import Keycloak from 'keycloak-js'

const keycloak = new Keycloak({
    url:process.env.REACT_APP_BLUSAPPHIRE_KEYCLOAK_DOMAIN,
    realm:process.env.REACT_APP_BLUSAPPHIRE_KEYCLOAK_REALM,
    clientId:process.env.REACT_APP_BLUSAPPHIRE_KEYCLOAK_CLIENT_ID,
    "credentials": {
        "secret": "FDIMTgY1txbSg7l99ZcryQHudgMSB2jC"
      }  
    
})


export default keycloak