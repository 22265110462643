import React, { useState, useEffect } from "react";
import keycloak from "./keycloak";
import { useParams } from "react-router-dom";

import {
  ApolloProvider,
  ApolloLink,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";

import { offsetLimitPagination } from "@apollo/client/utilities";

import {
  AUTH_TYPE,
  createAuthLink as awsCreateAuthLink,
} from "aws-appsync-auth-link";
import MainLoading from "./components/loading/MainLoading";

export default function AuthorizedApolloProvider(props) {
  
  const token  =  localStorage.getItem('token');

  const [authToken, setAuthToken] = useState(token);

  const url = process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT;

  const region = process.env.REACT_APP_AWS_APPSYNC_REGION;

  const auth = {
    type: AUTH_TYPE.OPENID_CONNECT,

    jwtToken: authToken,
  };

  const link = ApolloLink.from([
    awsCreateAuthLink({ url, region, auth }),

    createHttpLink({ uri: url }),
  ]);

  const client = new ApolloClient({
    link,

    connectToDevTools: false,

    cache: new InMemoryCache({
      typePolicies: {
        qquery: {
          fields: {
            getLogs: offsetLimitPagination(),
          },
        },
      },
    }),
  });

  useEffect(() => {
    if (!authToken) {
      if (!keycloak.didInitialize) {
        keycloak
          .init({
            onLoad: "login-required",
            checkLoginIframe: false,
            promiseType: "native",
          })
          .then(
            (auth) => {
              if (!auth) {
                window.location.reload();
              } else {
                //console.log('Keycloak', keycloak.token)

                setAuthToken(keycloak.token);

                keycloak.onTokenExpired = () => {
                  window.alert("Session expired. Please refresh the page.");
                };
              }
            },
            () => {
              console.error("Authenticated Failed");
            }
          );
      }
    }
  }, [!authToken]);

  if (authToken != null) {
    if (process.env.NODE_ENV === "development") {
    }

    return <ApolloProvider client={client}>{props.children}</ApolloProvider>;
  }

  return <MainLoading />;
}
