import {
  Button,
  Tooltip,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { exportLogsToCSV, timeCount } from "../query/query";
import { differenceInDays, startOfDay } from "date-fns";
import { Context } from "../context/Context";
import animationData from "../../assets/Animation - 1718863185260.json";
import Lottie from "lottie-react";
import excel from "../../assets/excel3.svg";
import csv from "../../assets/csv.json";
import CircularProgress from '@mui/material/CircularProgress';

function Export(props) {
  const [context] = React.useContext(Context);
  const [valid, setValid] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);

  const [fetchTimeCount, { loading: timeCountLoading }] = useLazyQuery(
    timeCount,
    {
      variables: {
        input: context["f_context"],
      },
      skip: context.f_context.org == "None",
      fetchPolicy: "no-cache",
      onCompleted: (data2) => {
        setValid(
          parseInt(data2.getCount, 10) <= 10000 &&
            parseInt(data2.getCount, 10) > 0
        );
      },
      onError: (error) => {
        console.error("Error : ", error);
        setValid(false);
      },
    }
  );

  const [fetchLogs] = useLazyQuery(exportLogsToCSV, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (valid) {
        setLoading(false);
        setDownloadUrl(data.exportLogsToCSV);
      }
    },
  });

  useEffect(() => {
    if (context.f_context.org !== "None") {
      fetchTimeCount();
    }
  }, [fetchTimeCount, context]);

  const handleExportClick = (event) => {
    let inputObject = context.f_context;

    inputObject.requiredcolumns = context.sColumns
      .filter((selected) => selected.field != "source")
      .map((selected) => ({
        field: selected.field,
        dtype: selected.type,
      }));

    setOpen(true);
    setLoading(true);
    setDownloadUrl(null);
    fetchLogs({
      variables: {
        input: inputObject,
      },
    });
  };
  const handleDownloadClick = () => {
    window.open(downloadUrl);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setLoading(false);
  };

  return (
    <>
      <Tooltip title="Download logs (Limit - 10,000 rows)">

          {/* {valid === true && ( */}
          {!timeCountLoading ? (
            <Button
              color="secondary"
              sx={{
                fontSize: ".875rem",
                fontFamily: "system-ui",
                lineHeight: "1.5rem",
                "&:hover": { fontWeight: "bold" },
              }}
              style={{
                backgroundColor: "transparent",
                color: valid ? "#11a1cd" : "lightgray",
              }}
              onClick={handleExportClick}
              disabled={!valid}
            >
              <CloudDownloadIcon />
            </Button>
          ) : (
            <div style={{ color: "#11a1cd",display:'flex',alignItems:'center' , justifyContent:'center',marginTop:'10px'}}> <CircularProgress size={16}/> </div>
          )}

        {/* )} */}
      </Tooltip>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="export-dialog-title"
        aria-describedby="export-dialog-description"
        PaperProps={{
          style: { width: "35rem", height: "25rem" },
        }}
      >
        <DialogTitle
          id="export-dialog-title"
          sx={{ display: "flex", justifyContent: "center" }}
        ></DialogTitle>
        <DialogContent
          sx={{ display: "flex", justifyContent: "center", alignItems: "end" }}
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "1rem",
                }}
              >
                <h6
                  style={{
                    fontWeight: "500",
                    color: "#1a1a1a",
                    fontSize: "19px",
                  }}
                >
                  We are preparing your Data..
                </h6>
                <Lottie
                  animationData={csv}
                  loop={true}
                  style={{ height: 250, width: 250 }}
                />
              </div>
            ) : (
              downloadUrl && (
                <>
                  <img
                    src={excel}
                    style={{
                      height: "16rem",
                      marginBottom: "0rem",
                      marginLeft: "2px",
                    }}
                  />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDownloadClick}
                    sx={{ mt: 1, width: "13.5rem" }}
                  >
                    Click to Download
                  </Button>
                </>
              )
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            color="primary"
            variant="filled"
            style={{
              backgroundColor: "#fafbfd",
              color: "#11a1cd",
              textTransform: "none",
              textDecoration: "underline",
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Export;
