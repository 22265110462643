import LaunchIcon from "@mui/icons-material/Launch";
import { Context } from "../context/Context";
import React, { useState } from "react";
import { Box } from "@mui/system";
import {
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Popover,
} from "@mui/material";
import { useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";

const ConfirmationDialog = ({ open, handleClose, handleConfirm }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Confirm Delete</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure, you want to delete the saved search?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
      <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',gap:10,marginRight:25,marginTop:-13,paddingBottom:'4px'}}>
        <Button onClick={handleClose} color="primary" variant="outlined" disableElevation size="small">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="error" variant="contained"  disableElevation autoFocus size="small">
          Delete
        </Button>
      </div>
      </DialogActions>
    </Dialog>
  );
};

function ContextList(props) {
  const items = { ...localStorage };

  const objectKeys = Object.keys(items);
  const [context, setContext] = React.useContext(Context);
  let { organizationId } = useParams();

  const loadContext = (one) => {
    let saved_context = JSON.parse(localStorage.getItem(one));
    if (saved_context) {
      setContext({
        ...saved_context,
        f_context: {
          ...saved_context.f_context,
          org: organizationId ? organizationId : "None",
        },
      });
    }
    props.handleClose();
  };

  const [open, setOpen] = useState(false);
  const [selectedAttribute, setSelectedAttribute] = useState(null);

  const handleDelete = () => {
    let saved_context = JSON.parse(localStorage.getItem(selectedAttribute));
    if (saved_context) {
      setContext({
        ...saved_context,
        f_context: {
          ...saved_context.f_context,
          filter: [],
          org: organizationId ? organizationId : "None",
        },
      });
    }
    localStorage.removeItem(selectedAttribute);
    props.handleClose();
  };

  const handleOpenDialog = (event, attribute) => {
    event.stopPropagation();
    setSelectedAttribute(attribute);
    setOpen(true);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const handleConfirmDialog = () => {
    handleDelete();
    setOpen(false);
  };

  return (
    <>
      {objectKeys
        .filter((value) => !value.includes("kc-callback"))
        .map((one) => {
          return (
            <MenuItem
              key={one}
              sx={{
                gap: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                flexDirection: "row",
                cursor: "default",
              }}
              onClick={() => {
                loadContext(one);
              }}
            >
              <Typography variant="body1">{one}</Typography>
              <DeleteIcon
                onClick={(event) => handleOpenDialog(event, one)}
                fontSize="12"
                color="action"
                sx={{
                  opacity: "75%",
                  ":hover": { color: "red" },
                  cursor: "pointer",
                }}
              />
            </MenuItem>
          );
        })}
      <ConfirmationDialog
        open={open}
        handleClose={handleCloseDialog}
        handleConfirm={handleConfirmDialog}
      />
    </>
  );
}

function OpenContext() {
  const [context, setContext] = React.useContext(Context);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Tooltip title="Open saved context">
        <button
          onClick={handleClick}
          aria-describedby={id}
          sx={{
            fontSize: ".875rem",
            fontFamily: "system-ui",
            lineHeight: "1.5rem",
            "&:hover": { fontWeight: "bold" },
          }}
          style={{
            backgroundColor: "transparent",
            color: "#006bb4",
            marginTop: ".47rem",
          }}
        >
          {"Open"}
        </button>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <ContextList handleClose={handleClose} />
      </Popover>
    </>
  );
}

export default OpenContext;
