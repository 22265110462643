import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MailIcon from "@mui/icons-material/Mail";
import IndexSelect from "../indexSelect/IndexSelect";
import FieldsSideMenu from "../fieldsSideMenu/FieldsSideMenu";
import TimeRangePicker from "../timeRangePicker/TimeRangePicker";
import Filter from "../filter/Filter";
import { Grid } from "@mui/material";
import OpenAi from "../filter/OpenAi";
import logo from "../../assets/logo12.png";
import { Button } from "rsuite";
import FilterView from "../filter/FilterView";
import SaveFilter from "../filter/SaveFilter";
import Export from "../logs/Export";
import OpenContext from "../filter/OpenContext";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

const drawerWidth = 300;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function MainAppBar(props) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
   const [appBarHeight, setAppBarHeight] = React.useState(0);
   const appbarRef = React.useRef(null);

React.useEffect(() => {
    const observer = new ResizeObserver(entries => {
      for (let entry of entries) {
        setAppBarHeight(entry.contentRect.height);
      }
    });

    if (appbarRef.current) {
      observer.observe(appbarRef.current);
    }

    return () => {
      if (appbarRef.current) {
        observer.unobserve(appbarRef.current);
      }
    };
  }, [appbarRef]);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        ref={appbarRef}
        position="fixed"
        sx={{ width: "100% !important", boxShadow: "none !important" }}
        open={open}
      >
        <Toolbar
          variant="dense"
          sx={{
            backgroundColor: "#fafbfd",
            display: "flex",
            minHeight: "38px !important",
          }}
        >
          <IconButton
            color="#757575"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>

          <img
            src={logo}
            style={{
              height: "1.7rem",
              width: "1.7rem",
              marginRight: ".5rem",
              marginLeft: "0rem",
            }}
          />
          <Divider
            sx={{ height: 22, m: 0.2, borderColor: "#ccc", mr: ".7rem" }}
            orientation="vertical"
          />
          {/* <Typography  sx={{backgroundColor:"#b9d9eb",padding:".25rem",color:"black",borderRadius:".25rem",fontSize:"14px",paddingLeft:".75rem",paddingRight:".75rem",marginLeft:".25rem"}}> Investigate</Typography> */}
          <h4 style={{ color: "#11a1cd", textTransform: "capitalize" }}>
            Investigate
          </h4>
          <Box sx={{ position: "absolute", right: "1rem", display: "flex" }}>
            <Grid container direction="row" spacing={1}>
              <Grid item>
                <OpenContext />
              </Grid>
              <Grid item>
                <SaveFilter />
              </Grid>
              <Grid item>
                <Export />
              </Grid>
            </Grid>
          </Box>
        </Toolbar>
        <Toolbar
          variant="dense"
          sx={{
            backgroundColor: "#fafbfd",
            borderTop: ".12rem solid #d0dadc",
            marginBottom: "-.45rem",
            position:'relative'
          }}
        >
          <OpenAi />

          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />

          <TimeRangePicker />
        </Toolbar>
        <Toolbar
          variant="dense"
          sx={{
            backgroundColor: "#fafbfd",
            borderBottom: ".12rem solid #d0dadc",
            minHeight: "42px !important",
            display:'flex',
            flexWrap:'wrap'
          }}
        >
          <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}>
          <FilterView />
          <Button
            style={{
              backgroundColor: "transparent",
              maxHeight: "2.2rem",
              marginRight: "1rem",
            }}
          >
            <Filter />
          </Button>
          </div>
        </Toolbar>
      </AppBar>

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            textAlign: "left",
            alignItems: "left",
            marginTop: `${appBarHeight-2}px`,
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IndexSelect />
          <IconButton
            onClick={handleDrawerClose}
            sx={{
              color: "#2CA5EE",
              marginTop: "-2.8rem",
              marginRight: "-.8rem",

            }}
          >
            {theme.direction === "ltr" ? <MenuOpenIcon sx={{height:"14px"}} /> : <MenuOpenIcon />}
          </IconButton>
        </DrawerHeader>

        <FieldsSideMenu />
      </Drawer>
      <Main open={open} style={{ width: "2%",paddingBottom:0, marginTop:`${appBarHeight-120}px` }}>
        <Box>
          <DrawerHeader />

          {props.children}
        </Box>
      </Main>
    </Box>
  );
}
