import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AuthorizedApolloProvider from './AuthorizedApolloProvider';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { 
  BrowserRouter as Router, 
  Route, 
  Routes,
} from "react-router-dom";
import MainApp from './App';
// import { InMemoryCache } from "@apollo/client";
// import { offsetLimitPagination } from "@apollo/client/utilities";


const theme = createTheme({
  palette: {
    primary: { 'main':'#2092bb'},
    secondary: { 'main':'#333333'},
  },
});

function App()
{
  return (
    <ThemeProvider theme={theme}>
    <AuthorizedApolloProvider>
   
     
      
      <MainApp />
   
      </AuthorizedApolloProvider>
      </ThemeProvider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  <Router> 
      <Routes> 
      <Route path="/org/:organizationId" element={<App/>}/> 
      <Route path="*" element={<App/>}/> 
    
   
    </Routes>
    </Router>
 
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
