// import { Box, Grid, Skeleton } from "@mui/material";

// function BarChartSkeleton() {
//   return (
//     <Box height={300}>
//       <Grid container spacing={2}>
//         {[...Array(12)].map((one) => {
//           return (
//             <Grid item xs={1}>
//               <Skeleton width={40} height={260} />
//             </Grid>
//           );
//         })}
//       </Grid>
//     </Box>
//   );
// }

// export default BarChartSkeleton;

import React from "react";
import { Box } from "@mui/material";
import Lottie from "lottie-react";
import animationData from "../../assets/Animation - 1718863185260.json"; // Replace with the path to your downloaded animation file


function BarChartSkeleton() {
  return (
    <Box
      height={300}
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Lottie
        animationData={animationData}
        loop={true}
        style={{ height: 250, width: 250 }}
      />
    </Box>

  );
}

export default BarChartSkeleton;
