import React, { useState, useEffect, useContext, useRef } from "react";
import { useQuery } from "@apollo/client";
import { timeCount } from "../query/query";
import {
  Bar,
  getElementAtEvent,
  getElementsAtEvent,
  getDatasetAtEvent,
} from "react-chartjs-2";
import "chartjs-adapter-date-fns";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  TimeSeriesScale,
  Colors,
} from "chart.js";
import {
  colors,
  IconButton,
  Tooltip as Tip,
  Typography,
  Switch,
} from "@mui/material";
import { ToggleOn, ToggleOff, Bolt, Timer } from "@mui/icons-material";
import { Box } from "@mui/system";
import { Context } from "../context/Context";
import { addSeconds, differenceInHours } from "date-fns";
import BarChartSkeleton from "../skeleton/BarChartSkeleton";
import SortIcon from "@mui/icons-material/Sort";

import { UTCDate } from "@date-fns/utc";
import { da, tr } from "date-fns/locale";
ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  Colors,
  CategoryScale,
  LinearScale,
  TimeSeriesScale,
  BarElement,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler
);

function TimeBar(props) {
  const [context, setContext] = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const chartRef = useRef();
  const [toggle, setToggle] = useState(false);

  const onClick = (event) => {
    const { current: chart } = chartRef;

    if (!chart) {
      return;
    }

    var element = getElementAtEvent(chart, event);

    if (element) {
      try {
        let index = element[0].index;
        let from_d = new Date(chart_data.labels[index]);
        let to_d = from_d;
        let group = context.f_context["group"];

        if (group === "minute") {
          to_d = addSeconds(from_d, 59);
        } else if (group === "hour") {
          to_d = addSeconds(from_d, 1 * 60 * 60 - 1);
        } else if (group === "day") {
          to_d = addSeconds(from_d, 24 * 60 * 60 - 1);
        }

        let diff = differenceInHours(to_d, from_d);

        if (diff > 2 && diff < 49) {
          group = "hour";
        } else if (diff > 48) {
          group = "day";
        } else {
          group = "minute";
        }
        const now = new Date();

        // Get the timezone offset in minutes and convert to hours
        // const timeZoneOffsetInHours = -now.getTimezoneOffset() / 60;
        // const timeZoneOffInMilliSecs = timeZoneOffsetInHours*60*60*1000;
        // let newFromDate = new Date(from_d - timeZoneOffInMilliSecs);
        // let newToDate = new Date(to_d - timeZoneOffInMilliSecs);
        //  console.log(newFromDate.toISOString(), newToDate.toISOString(), from_d.toISOString(), to_d.toISOString());
        //if(group !== 'minute')
        // {

        setContext({
          ...context,
          f_context: {
            ...context.f_context,
            fromDttm: from_d.toISOString(),
            toDttm: to_d.toISOString(),
            group: group,
          },
        });
        //}
      } catch (error) {}
    }
  };

  useEffect(() => {
    setContext({
      ...context,
      f_context: {
        ...context.f_context,
        orderbyflag: toggle,
      },
    });
  }, [toggle]);

  const chart_data = {
    labels: [],

    datasets: [
      {
        label: "# Count",

        data: [],
        // backgroundColor:'#2092bb',
        backgroundColor: "#4B9CD3",
        radius: 73,
      },
    ],
  };

  //query tables
  const { data, loading, error, refetch } = useQuery(timeCount, {
    fetchPolicy: "cache-first",

    variables: {
      input: context["f_context"],
    },
    skip: context.f_context.org == "None",
  });

  useEffect(() => {
    if (context["refetchQs"]) {
      handleRefresh();
    }
  }, [context["refetchQs"]]);

  const handleRefresh = async () => {
    setIsLoading(true);
    const {data} = await refetch();
    console.log("refetch data",data.getCount);
    setIsLoading(false);
    setContext({
      ...context,
      refetchQs: false,
      totalHits:data.getCount
    });
  };

  useEffect(() => {
    if (data) {
      chart_data.labels = data.getTimeCount.map((a) => new Date(a.time + "Z"));
      chart_data.datasets[0].data = data.getTimeCount.map((a) => a.count);
      let timeData = data.getTimeCount.filter((i) => i.count !== 0);
      timeData = timeData.sort((a, b) => new Date(b.time) - new Date(a.time));

      if (timeData.length > 0 && timeData[0].time !== context.refactorDttm) {
        let date = new Date(timeData[0].time + "Z");

        const fromDate = new Date(context["f_context"].fromDttm);
        const toDate = new Date(context["f_context"].toDttm);

        // Calculate the difference in milliseconds
        const diffInMillis = Math.abs(toDate - fromDate);

        // Define constants for time calculations
        const ONE_DAY = 24 * 60 * 60 * 1000;
        const ONE_HOUR = 60 * 60 * 1000;
        const ONE_MINUTE = 60 * 1000;

        // Check if the difference is in days, hours, minutes, or seconds and adjust accordingly
        if (diffInMillis >= ONE_DAY) {
          // If the difference is in days, add 23 hours, 59 minutes, and 59 seconds
          date.setHours(date.getHours() + 23);
          date.setMinutes(date.getMinutes() + 59);
          date.setSeconds(date.getSeconds() + 59);
        } else if (diffInMillis >= ONE_HOUR) {
          // If the difference is in hours, add the corresponding hours minus one to account for zero-indexing
          const diffHours = Math.floor(diffInMillis / ONE_HOUR);

          date.setHours(date.getHours() + diffHours - 1);
          date.setMinutes(date.getMinutes() + 59);
          date.setSeconds(date.getSeconds() + 59);
          console.log("change in hours");
        } else if (diffInMillis >= ONE_MINUTE) {
          // If the difference is in minutes, add the corresponding minutes minus one to account for zero-indexing
          const diffMinutes = Math.floor(diffInMillis / ONE_MINUTE);

          date.setMinutes(date.getMinutes() + diffMinutes - 1);
          date.setSeconds(date.getSeconds() + 59);
          console.log("change in minutes");
        } else {
          // If the difference is in seconds, add the corresponding seconds minus one to account for zero-indexing
          const diffSeconds = Math.floor(diffInMillis / 1000);

          date.setSeconds(date.getSeconds() + diffSeconds - 1);
          console.log("change in seconds");
        }
        console.log(date.toLocaleString());
        setContext({
          ...context,
          refactorDttm: date,
        });
      }
    }
  }, [data]);

  useEffect(() => {
    if (data?.getCount) {
      setContext({
        ...context,
        totalHits: data.getCount,
      });
    }
  }, [data]);

  if (data) {
    chart_data.labels = data.getTimeCount.map((a) => new Date(a.time + "Z"));
    chart_data.datasets[0].data = data.getTimeCount.map((a) => a.count);
  }

  return (
    <Box sx={{ marginTop: "2.4rem" }}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box flex="1" textAlign="center" marginRight="-12rem">
          <code>
            <b>Hits : {data?.getCount}</b>
          </code>
        </Box>
        <Box display="flex" alignItems="center" gap={0.4}>
          <Typography
            variant="body2"
            sx={{
              width: "100px",
              backgroundColor: "#f5f7fa",
              borderRadius: "5px",
            }}
          >
            {toggle ? "Chronological" : "Blazing Fast"}
          </Typography>
          <Tip arrow title="Gets results faster">
            <Bolt
              sx={{ color: toggle ? "lightgray" : "#FF8F00", height: "16px" }}
            />
          </Tip>
          <IconButton
            onClick={() => setToggle(!toggle)}
            color="primary"
            sx={{
              height: "15px",
              width: "15px",
              color: toggle ? "#11a1cd" : "#FF8F00",
              borderRadius: "50%",
            }}
          >
            {toggle ? <ToggleOn /> : <ToggleOff />}
          </IconButton>
          <Tip arrow title="Gets results in reverse chronological order">
            <SortIcon
              sx={{ color: toggle ? "#2092bb" : "lightgray", height: "15px" }}
            />
          </Tip>
        </Box>
      </Box>
      <Box>
        <code style={{ fontSize: ".8em" }}>
          {new Date(context.f_context["fromDttm"])
            .toString()
            .replace("India Standard Time", "IST")
            .replace("GMT+0530", "")}{" "}
          ~{" "}
          {new Date(context.f_context["toDttm"])
            .toString()
            .replace("India Standard Time", "IST")
            .replace("GMT+0530", "")}
        </code>
      </Box>
      <Box sx={{ maxHeight: "10em" }}>
        {loading || isLoading ? (
          <BarChartSkeleton />
        ) : (
          <Bar
            ref={chartRef}
            data={chart_data}
            height={200}
            options={{
              maintainAspectRatio: false,
              onHover: function (e) {
                // console.log(e.target)
                e.native.target.style.cursor = "pointer";
              },
              plugins: {
                legend: {
                  position: "top",

                  align: "center",

                  display: false,
                },
              },
              scales: {
                x: {
                  grid: {
                    display: true,
                  },
                  border: {
                    dash: [1, 10],
                  },
                  type: "timeseries",
                  ticks: {
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                  },
                },
                y: {
                  grid: {
                    display: true,
                  },
                  ticks: {
                    stepSize: 50,
                    maxTicksLimit: 4,
                  },
                  border: {
                    dash: [3, 4],
                  },
                },
              },
            }}
            onClick={onClick}
          />
        )}
      </Box>
    </Box>
  );
}

export default TimeBar;
