// import { Chip, Stack } from "@mui/material";
// import { Context } from "../context/Context";
// import React from "react";
// import ClearIcon from "@mui/icons-material/Clear";

// function FilterView() {
//   const [context, setContext] = React.useContext(Context);
//   console.log(context);

//   function handleDelete(id) {
//     let filter = context.f_context.filter.filter((one) => one.Id !== id);

//     setContext({
//       ...context,
//       f_context: { ...context.f_context, filter: filter },
//     });
//   }

//   function handleDisable(f) {
//     let newfilter = {
//       ...f,
//       status: f.status === "active" ? "disabled" : "active",
//     };

//     let filter = context.f_context.filter.filter((one) => one.Id !== f.Id);

//     filter.push(newfilter);

//     setContext({
//       ...context,
//       f_context: { ...context.f_context, filter: filter },
//     });
//   }

//   return (
//     <Stack direction="row" spacing={1}>
//       {context.f_context.filter.map((f) => {
//         let value = "";
//         let color = "primary";

//         if (f.op.indexOf("NOT CONTAINS") !== -1) {
//           value = f.field + " != %" + f.value.replace("#@#", "~") + "%";
//           color = "error";
//         } else if (f.op.indexOf("CONTAINS") !== -1) {
//           value = f.field + "= %" + f.value.replace("#@#", "~") + "%";
//         } else if (f.op.indexOf("NOT") !== -1) {
//           value = f.field + " != " + f.value.replace("#@#", "~");
//           color = "error";
//         } else {
//           value = f.field + " = " + f.value.replace("#@#", "~");
//         }

//         return (
//           <Chip
//             label={value}
//             color={f.status === "active" ? color : "secondary"}
//             variant="filled"
//             size="small"
//             deleteIcon={<ClearIcon />}
//             onDelete={() => {
//               handleDelete(f.Id);
//             }}
//             onClick={() => {
//               handleDisable(f);
//             }}
//             sx={{ borderRadius: ".2rem" }}
//           />
//         );
//       })}
//     </Stack>
//   );
// }

// export default FilterView;

//// --------------------------------------------version 1 ------------------------------///


// import { Chip, Stack } from '@mui/material';
// import { Context } from '../context/Context';
// import React from 'react';
// import ClearIcon from '@mui/icons-material/Clear';

// function FilterView() {
//     const [context, setContext] = React.useContext(Context);
//     console.log(context);

//     function handleDelete(id) {
//         let filter = context.f_context.filter.filter((one) => one.Id !== id);
//         setContext({ ...context, f_context: { ...context.f_context, filter: filter } });
//     }

//     function handleDisable(f) {
//         let newfilter = { ...f, 'status': f.status === 'active' ? 'disabled' : 'active' };
//         let filter = context.f_context.filter.filter((one) => one.Id !== f.Id);
//         filter.push(newfilter);
//         setContext({ ...context, f_context: { ...context.f_context, filter: filter } });
//     }

//     const sortedFilters = context.f_context.filter.slice().sort((a, b) => {
//         if (a.status === 'active' && b.status === 'disabled') return -1;
//         if (a.status === 'disabled' && b.status === 'active') return 1;
//         return 0;
//     });

//     return (
//         <Stack direction="row" spacing={1}>
//             {sortedFilters.map((f) => {
//                 let value = "";
//                 let color = "primary";

//                 if (f.op.indexOf('NOT CONTAINS') !== -1) {
//                     value = f.field + ' != %' + f.value.replace('#@#', '~') + '%';
//                     color = "error";
//                 } else if (f.op.indexOf('CONTAINS') !== -1) {
//                     value = f.field + '= %' + f.value.replace('#@#', '~') + '%';
//                 } else if (f.op.indexOf('NOT') !== -1) {
//                     value = f.field + ' != ' + f.value.replace('#@#', '~');
//                     color = "error";
//                 } else {
//                     value = f.field + ' = ' + f.value.replace('#@#', '~');
//                 }

//                 return (
//                     <Chip
//                         key={f.Id}
//                         label={value}
//                         color={f.status === 'active' ? color : 'secondary'}
//                         variant="outlined"
//                         size='small'
//                         deleteIcon={<ClearIcon />}
//                         onDelete={() => { handleDelete(f.Id); }}
//                         onClick={() => { handleDisable(f); }}
//                         sx={{ borderRadius: '.2rem',height:'2.3rem',color:'black' }}
//                     />
//                 );
//             })}
//         </Stack>
//     );
// }

// export default FilterView;

///--------------------------------version 2 --------------------------------//

// import React, { useState } from "react";
// import { Chip, Stack, Menu, MenuItem } from "@mui/material";
// import { Context } from "../context/Context";

// function FilterView() {
//   const [context, setContext] = React.useContext(Context);
//   const [anchorEl, setAnchorEl] = useState(null);
//   const [selectedFilter, setSelectedFilter] = useState(null);

//   const handleClick = (event, filter) => {
//     setAnchorEl(event.currentTarget);
//     setSelectedFilter(filter);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//     setSelectedFilter(null);
//   };

//   const handleDisable = () => {
//     if (selectedFilter) {
//       let newfilter = {
//         ...selectedFilter,
//         status: selectedFilter.status === "active" ? "disabled" : "active",
//       };
//       let filter = context.f_context.filter.filter(
//         (one) => one.Id !== selectedFilter.Id
//       );
//       filter.push(newfilter);
//       setContext({
//         ...context,
//         f_context: { ...context.f_context, filter: filter },
//       });
//       handleClose();
//     }
//   };

//   const handleDelete = (id) => {
//     let filter = context.f_context.filter.filter((one) => one.Id !== id);
//     setContext({
//       ...context,
//       f_context: { ...context.f_context, filter: filter },
//     });
//   };

//   const sortedFilters = context.f_context.filter.slice().sort((a, b) => {
//     if (a.status === "active" && b.status === "disabled") return -1;
//     if (a.status === "disabled" && b.status === "active") return 1;
//     return 0;
//   });

//   return (
//     <>
//       <Stack direction="row" spacing={1}>
//         {sortedFilters.map((f) => {
//           let value = "";
//           let color = "primary";

//           if (f.op.indexOf("NOT CONTAINS") !== -1) {
//             value = f.field + " != %" + f.value.replace("#@#", "~") + "%";
//             color = "error";
//           } else if (f.op.indexOf("CONTAINS") !== -1) {
//             value = f.field + "= %" + f.value.replace("#@#", "~") + "%";
//           } else if (f.op.indexOf("NOT") !== -1) {
//             value = f.field + " != " + f.value.replace("#@#", "~");
//             color = "error";
//           } else {
//             value = f.field + " = " + f.value.replace("#@#", "~");
//           }

//           const labelStyle =
//             f.status === "disabled" ? { textDecoration: "line-through" } : {};


//           return (
//             <Chip
//               key={f.Id}
//               label={<span style={labelStyle}>{value}</span>}
//               color={f.status === "active" ? color : "secondary"}
//               variant="outlined"
//               size="small"
//               onDelete={() => {
//                 handleDelete(f.Id);
//               }}
//               onClick={(event) => {
//                 handleClick(event, f);
//               }}
//               sx={{ borderRadius: ".2rem", height: "2rem", color: "black" }}
//             />
//           );
//         })}
//       </Stack>
//       <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
//         <MenuItem onClick={handleDisable}>
//           {selectedFilter?.status === "active" ? "Temporary Disable" : "Re-Enable"}
//         </MenuItem>
//       </Menu>
//     </>
//   );
// }

// export default FilterView;



import React, { useState } from "react";
import { Chip, Stack, Menu, MenuItem, styled, Box, IconButton } from "@mui/material";
import { Context } from "../context/Context";
import { Clear } from "@mui/icons-material";
import FilterListIcon from '@mui/icons-material/FilterList';
import { Form } from "./Filter";
import Popover from "@mui/material/Popover";

function FilterView() {
  const [context, setContext] = React.useContext(Context);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl1, setAnchorEl1] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const handleClick = (event, filter) => {
    setAnchorEl(event.currentTarget);
    setSelectedFilter(filter);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedFilter(null);
    setAnchorEl1(null);
  };

  const handleDisable = () => {
    if (selectedFilter) {
      let newfilter = {
        ...selectedFilter,
        status: selectedFilter.status === "active" ? "disabled" : "active",
      };
      let filter = context.f_context.filter.filter(
        (one) => one.Id !== selectedFilter.Id
      );
      filter.push(newfilter);
      setContext({
        ...context,
        f_context: { ...context.f_context, filter: filter },
      });
      handleClose();
    }
  };

  const handleEditFilter = (event) => {
    if (selectedFilter) {
      // let newfilter = {
      //   ...selectedFilter,
      //   status: selectedFilter.status === "active" ? "disabled" : "active",
      // };
      // let filter = context.f_context.filter.filter(
      //   (one) => one.Id !== selectedFilter.Id
      // );
      // filter.push(newfilter);
      // setContext({
      //   ...context,
      //   f_context: { ...context.f_context, filter: filter },
      // });
      setAnchorEl1(event.currentTarget);
      setAnchorEl(null);
      let id = selectedFilter.Id;
      // let filter = context.f_context.filter.filter((one) => one.Id !== id);
      //   setContext({
      //     ...context,
      //     f_context: { ...context.f_context, filter: filter },
      //   });
      // handleClose();
    }
  };


  const handleDelete = (id) => {
    let filter = context.f_context.filter.filter((one) => one.Id !== id);
    setContext({
      ...context,
      f_context: { ...context.f_context, filter: filter },
    });
  };
  const handleEnableAll = () => {
    let updatedFilters = context.f_context.filter.map((f) => ({
      ...f,
      status: "active",
    }));
    setContext({
      ...context,
      f_context: { ...context.f_context, filter: updatedFilters },
    });
    setMenuAnchorEl(null);
  };

  const handleDisableAll = () => {
    let updatedFilters = context.f_context.filter.map((f) => ({
      ...f,
      status: "disabled",
    }));
    setContext({
      ...context,
      f_context: { ...context.f_context, filter: updatedFilters },
    });
    setMenuAnchorEl(null);
  };

  const handleDeleteAll = () => {
    setContext({
      ...context,
      f_context: { ...context.f_context, filter: [] },
    });
    setMenuAnchorEl(null);
  }

  const sortedFilters = context.f_context.filter.slice().sort((a, b) => {
    if (a.status === "active" && b.status === "disabled") return -1;
    if (a.status === "disabled" && b.status === "active") return 1;
    return 0;
  });

  // const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl1);
  // const id = open ? "simple-popover" : undefined;

  return (
    <>
      <Box display={"flex"} flexDirection={"row"} sx={{ marginTop: 1, "flexWrap": "wrap", gap: 1 }}>
        <IconButton
          sx={{ height: '.2rem', width: '.2rem', padding: '7px', border: '1px solid #11a1cd' }}
          onClick={(event) => setMenuAnchorEl(event.currentTarget)}
        >
          <FilterListIcon sx={{ height: '12px', color: '#11a1cd' }} />
        </IconButton>
        <Menu
          anchorEl={menuAnchorEl}
          open={Boolean(menuAnchorEl)}
          onClose={() => setMenuAnchorEl(null)}
        >
          <MenuItem onClick={handleEnableAll}>Enable All</MenuItem>
          <MenuItem onClick={handleDisableAll}>Disable All</MenuItem>
          <MenuItem onClick={handleDeleteAll}>Delete All</MenuItem>
        </Menu>
        {sortedFilters.map((f) => {
          let value = "";
          let color = "primary";

          if (f.op === "EXISTS" || f.op === "NOT EXISTS") {
            value = f.field + " " + f.op;
            if (f.op === 'NOT EXISTS') { value = f.field + " " + f.op; color = 'error'; }
          } else if (f.op.indexOf("NOT CONTAINS") !== -1) {
            value = f.field + " != %" + f.value.replace("#@#", "~") + "%";
            color = "error";
          } else if (f.op.indexOf("CONTAINS") !== -1) {
            value = f.field + "= %" + f.value.replace("#@#", "~") + "%";
          } else if (f.op.indexOf("NOT") !== -1) {
            value = f.field + " != " + f.value.replace("#@#", "~");
            color = "error";
          } else {
            value = f.field + " = " + f.value.replace("#@#", "~");
          }

          const labelStyle =
            f.status === "disabled" ? { textDecoration: "line-through" } : {};

          const chipStyle = f.status === "disabled" ? {

            backgroundColor: "#dddd",
            borderColor: "#f5f5f5",
            color: "#888"
          } : {};

          return (
            <Chip
              key={f.Id}
              label={<span style={labelStyle}>{value}</span>}
              color={f.status === "active" ? color : "secondary"}
              variant="outlined"
              size="small"
              deleteIcon={<Clear />}
              onDelete={() => {
                handleDelete(f.Id);
              }}
              onClick={(event) => {
                handleClick(event, f);
              }}
              sx={{

                borderRadius: ".2rem",
                height: "1.38rem",
                marginTop: '-1px',
                color: "black",
                border: f.status === "active" && color === "error" ? "1px solid red" : "1px solid #ccc",
                gap: '10px',
                ...chipStyle
              }}
            />
          );
        })}
      </Box>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleDisable}>
          {selectedFilter?.status === "active" ? "Temporary Disable" : "Re-Enable"}
        </MenuItem>
        <MenuItem onClick={handleEditFilter}>
          Edit Filter
        </MenuItem>
      </Menu>
      {/* <Form/> */}
      <Popover
        sx={{ marginTop: ".5rem", marginLeft: ".5rem",marginTop:'-4rem !important' }}
        // id={id}
        open={open}
        anchorEl={anchorEl1}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Form handleClose={handleClose} selectedFilter={selectedFilter}  />
      </Popover>
    </>
  );
}

export default FilterView;
