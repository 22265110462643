import "./App.css";
import base64 from "base64-js";
import { Grid } from "@mui/material";
import { UTCDate } from "@date-fns/utc";
import { useQuery } from "@apollo/client";
import { useState,useEffect } from "react";
import TimeBar from "./components/time/TimeBar";
import { columns } from "./components/query/query";
import LogsTable from "./components/logs/LogsTable";
import { Context } from "./components/context/Context";
import MainAppBar from "./components/appbar/MainAppBar";
import { useParams, useLocation } from "react-router-dom";
import { differenceInDays, differenceInHours,addMinutes } from "date-fns";

function MainApp() {
  let { organizationId } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const nib = queryParams.get("nib");
  const [acolumns, setAcolumns] = useState([]);
  const [decodedData, setDecodedData] = useState(null);

  const default_context = {
    sColumns: [
      { field: "event.created", type: "datetime", __typename: "column" },
      { field: "source", type: "json", __typename: "column" },
    ],
    aColumns: [],
    f_context: {
      fromDttm: addMinutes(new UTCDate(), -15).toISOString(),
      toDttm: new UTCDate().toISOString(),
      rowLimit: 200,
      rowOffset: 0,
      filter: [],
      table: "ngfw",
      group: "minute",
      org: organizationId ? organizationId : "None",
    },
  };

  let saved_context = JSON.parse(localStorage.getItem('BLU_DISCOVER'))

  if (null === saved_context) {
    saved_context = default_context;
  }

  const [context, setContext] = useState({
    ...saved_context,
    f_context: {
      ...saved_context.f_context,
      org: organizationId ? organizationId : "None",
    },
  });

  const { data, loading, error } = useQuery(columns, {
    fetchPolicy: "cache-first",
    variables: {
      input: {
        fromDttm: "",
        toDttm: "",
        rowLimit: 200,
        rowOffset: 0,
        filter: [],
        table: decodedData?.table,
        group: "",
        org: decodedData?.org,
      },
    },
    skip: !decodedData,
  });

  useEffect(() => {
    if (nib !== null) {
      try {
        const base64String = nib;
        const bytes = base64.toByteArray(base64String);
        const jsonString = new TextDecoder().decode(bytes);
        const decoded = JSON.parse(jsonString);

        // Update 'group' based on time difference
        if (differenceInDays(new Date(decoded.toDttm), new Date(decoded.fromDttm)) >= 1) {
          decoded.group = "day";
        } else if (differenceInHours(new Date(decoded.toDttm), new Date(decoded.fromDttm)) >= 1) {
          decoded.group = "hour";
        } else {
          decoded.group = "minute";
        }

        setDecodedData(decoded);
      } catch (error) {
        console.error('Error decoding or parsing JSON:', error);
      }
    }
  }, [nib]);

  useEffect(() => {
    if (data) {
      setAcolumns(data.getColumns);
    }
  }, [data]);

  useEffect(() => {
    if (decodedData && acolumns.length !== 0) {
      let filteredColumns = decodedData.sColumns
        .map(key => acolumns.find(column => column.field === key))
        .filter(Boolean);

      if (!filteredColumns.some(col => col.field === "source")) {
        filteredColumns.splice(1, 0, { field: "source", type: "json", __typename: "column" });
      }

      setContext((prevContext) => ({
        ...prevContext,
        sColumns: filteredColumns,
        f_context: {
          ...prevContext.f_context,
          fromDttm: decodedData.fromDttm,
          toDttm: decodedData.toDttm,
          group: decodedData.group,
          table: decodedData.table,
          filter: decodedData.filter,
          org: decodedData.org,
        },
      }));
    }
  }, [acolumns, decodedData]);

  if (error) {
    console.log(error);
  }

  return (
    <div className="App">
      <Context.Provider value={[context, setContext]}>
        <MainAppBar>
          <Grid container spacing={2}>
            {/* <Grid item xs={12}>
              <FilterView />
            </Grid> */}

            <Grid item xs={12}>
              <TimeBar />
            </Grid>

            <Grid item xs={12}>
              <LogsTable />
            </Grid>
          </Grid>
        </MainAppBar>
      </Context.Provider>
    </div>
  );
}

export default MainApp;

