import { gql } from "@apollo/client";





export const tables = gql`

  query getTables($input: String!) {

    getTables(input: $input)

  }

`;

export const roles = gql`

  query getRoles($input: String!) {

    getRoles(input: $input)

  }

`;

export const columns = gql`

  query getColumns($input: context!) {

    getColumns(input: $input) {
       field
       type

    }

  }

`;

export const originalEvent = gql`

  query getRawData($input: context!) {

    getRawData(input: $input)

  }

`;


export const timeCount = gql`

  query getTimeCount($input: context!) {

    getTimeCount(input: $input) {
       time
       count

    }


      getCount(input: $input)




  }

`;


export const count = gql`

  query getCount($input: context!) {

    getCount(input: $input)

  }

`;

export const logs = gql`

  query getLogs($input: context!) {

    getLogs(input: $input)

  }

`;

export const openai = gql`

  query getAIQuery($input: ai!) {

    getAIQuery(input: $input)

  }

`;



export const exportLogsToCSV = gql`

  query exportLogsToCSV($input: context!) {

    exportLogsToCSV(input: $input)

  }

`;




