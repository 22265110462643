
import Button from '@mui/material/Button';
import SaveIcon from '@mui/icons-material/Save';
import { Context } from '../context/Context';
import React from 'react';
import { Box } from '@mui/system';
import { FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material';
import Popover from '@mui/material/Popover';

function Form(props)
{

  const [context,setContext] = React.useContext(Context)
  const [name, setName] = React.useState(context.f_context.table);



  const handleChange = (event) => {
    const newName = event.target.value;
    setName(newName);
  }

  const saveContext = (event)=>{

    localStorage.setItem(name, JSON.stringify(context));
    props.handleClose()

  }

  return <Box sx={{width:"20em"}} p={2}>
  <Grid container spacing={2}>

  <Grid item xs={12}>
  <FormControl fullWidth>
  <TextField size="small" value={name}  labelId="field-select-label" id="value-text" label="Name" error={name?'':'Please enter a valid name.'} onChange={handleChange}/>
    </FormControl>
    </Grid>
    </Grid>
    <Grid item xs={12}>
      <Box sx={{textAlign: 'right',paddingTop:'1em'}}>

      <Button size='small' variant='outlined' disabled={name?false:true} onClick={saveContext}>Save</Button>

      </Box>
    </Grid>

    </Box>

}


function SaveFilter()
{

    const [context,setContext] = React.useContext(Context)


  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

    return (
      <>
      <Tooltip title="Save">
      <button onClick={handleClick} aria-describedby={id} sx={{fontSize:".875rem",fontFamily:"system-ui",lineHeight:"1.5rem",'&:hover': {fontWeight: 'bold'}}} style={{backgroundColor:"transparent",color:"#006bb4",marginTop:".45rem"}}>

      {"Save"}

      </button>
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
      >
       <Form handleClose={handleClose}/>
      </Popover>
      </>

    )
}


export default  SaveFilter;