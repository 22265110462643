import { Box, maxHeight } from "@mui/system";
import OrgSelect from "../orgSelect/OrgSelect";
import {
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { columns, count, logs, timeCount } from "../query/query";
import React, { useState, useEffect, useContext } from "react";
import { CalendarMonth } from "@mui/icons-material";
import DataObjectIcon from "@mui/icons-material/DataObject";
import { Context } from "../context/Context";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";

function fieldType(field) {
  switch (field) {
    case "datetime":
      return <CalendarMonth />;

    case "json":
      return <DataObjectIcon />;

    case "integer" || "double":
      return <p style={{border:'.5px solid #ccc',fontSize:13,fontWeight:550,backgroundColor:'#eff4f9',borderRadius:'3px',color:'#4a7194',paddingLeft:'5px',paddingRight:'5px',fontStyle:'italic'}}>n</p>;

    case "array(varchar)":
      // return <DataArrayIcon style={{border:'.5px solid #ccc',fontSize:"18px",fontWeight:600,backgroundColor:'#eff4f9',borderRadius:'3px',color:'#4a7194',fontStyle:'italic',paddingLeft:"1px",paddingRight:"1px",paddingTop:"2px",paddingBottom:"2px"}} />;
      return <p style={{border:'.5px solid #ccc',fontSize:12,fontWeight:550,backgroundColor:'#eff4f9',borderRadius:'3px',color:'#4a7194',paddingLeft:'2px',paddingRight:'2px',paddingBottom:"2px"}}>[ ]</p>;

    default:
      return <p style={{border:'.5px solid #ccc',fontSize:13,fontWeight:550,backgroundColor:'#eff4f9',borderRadius:'3px',color:'#4a7194',paddingLeft:'5px',paddingRight:'5px',fontStyle:'italic'}}>t</p>;
  }
}

function AMenuItem(props) {
  const [context, setContext] = useContext(Context);
  const [visible, setVisible] = useState(false);
  const { column } = props;
  const selectColumn = () => {
    setContext({ ...context, sColumns: [...context.sColumns, column] });
  };



  const formatText = (text, maxLength) => {
  let result = '';
  let line = '';
  let lastDotIndex = -1;

  for (let i = 0; i < text.length; i++) {
    const char = text[i];
    line += char;

    if (char === '.') {
      lastDotIndex = i;
    }

    if (line.length >= maxLength) {
      if (lastDotIndex !== -1) {
        result += text.substring(result.length, lastDotIndex + 1) + '\n';
        i = lastDotIndex;
        line = '';
        lastDotIndex = -1;
      } else {
        result += line + '\n';
        line = '';
      }
    }
  }

  result += line;
  return result;
};

  return (
    <MenuItem
      onMouseEnter={() => {
        setVisible(true);
      }}
      onMouseLeave={() => {
        setVisible(false);
      }}
    >
      <ListItemIcon sx={{marginRight:'-.7rem',marginLeft:'-.5rem',height:'1rem'}}>
        <code> {fieldType(column.type)}</code>
        {/* <code style={{border:'.5px solid #ccc',fontSize:13,fontWeight:550,backgroundColor:'#eff4f9',borderRadius:'3px',color:'#4a7194',paddingLeft:'5px',paddingRight:'5px',fontStyle:'italic'}}><p style={{marginTop:'-.15rem'}}>t</p></code> */}
      </ListItemIcon>
      <Tooltip title={column.field}>
        <ListItemText>
          <code>
            <Typography variant="inherit" noWrap={false} sx={{whiteSpace:'pre-wrap',wordBreak:'break-word'}}>
              {/* {column.field} */}
              {formatText(column.field, 23)}
            </Typography>
          </code>
        </ListItemText>
      </Tooltip>
      <ListItemIcon></ListItemIcon>
      {visible ? (
        <ListItemIcon size="small">
          <code>
            <Tooltip title="Add Column">
              <IconButton size="small" onClick={selectColumn}>
                <AddIcon size="small" fontSize="1px" color="primary" />
              </IconButton>
            </Tooltip>
          </code>
        </ListItemIcon>
      ) : (
        <></>
      )}
    </MenuItem>
  );
}

function SMenuItem(props) {
  const [context, setContext] = useContext(Context);
  const [visible, setVisible] = useState(false);
  const { column } = props;
  const removeColumn = () => {
    const newColumns = context.sColumns.filter((a) => a !== column);
    setContext({ ...context, sColumns: newColumns });
  };

  const formatText = (text, maxLength) => {
  let result = '';
  let line = '';
  let lastDotIndex = -1;

  for (let i = 0; i < text.length; i++) {
    const char = text[i];
    line += char;

    if (char === '.') {
      lastDotIndex = i;
    }

    if (line.length >= maxLength) {
      if (lastDotIndex !== -1) {
        result += text.substring(result.length, lastDotIndex + 1) + '\n';
        i = lastDotIndex;
        line = '';
        lastDotIndex = -1;
      } else {
        result += line + '\n';
        line = '';
      }
    }
  }

  result += line;
  return result;
};

  return (
    <MenuItem
      onMouseEnter={() => {
        setVisible(true);
      }}
      onMouseLeave={() => {
        setVisible(false);
      }}
    >
      <ListItemIcon sx={{marginRight:'-.7rem',marginLeft:'-.5rem',height:'1rem'}}>
        <code> {fieldType(column.type)}</code>
      </ListItemIcon>
      {/* <ListItemIcon sx={{marginRight:'-.7rem',marginLeft:'-.5rem',height:'1rem'}}>
        <code style={{border:'.5px solid #ccc',fontSize:13,fontWeight:550,backgroundColor:'#eff4f9',borderRadius:'3px',color:'#4a7194',paddingLeft:'5px',paddingRight:'5px',fontStyle:'italic'}}><p style={{marginTop:'-.15rem'}}>t</p></code>
      </ListItemIcon> */}

      <ListItemText>
        <Tooltip title={column.field}>
          <code>
            <Typography variant="inherit" noWrap>
              {/* {column.field} */}
              {formatText(column.field, 23)}
            </Typography>
          </code>
        </Tooltip>
      </ListItemText>

      {visible &&
      column.field !== "event.created" &&
      column.field !== "source" && column.field !== "alert.created"  ? (
        <ListItemIcon size="small">
          <code>
            <>
              <Tooltip title="Remove Column">
                <IconButton size="small" onClick={removeColumn}>
                  <CloseIcon size="small" fontSize="1px" color="error" />
                </IconButton>
              </Tooltip>
            </>
          </code>
        </ListItemIcon>
      ) : (
        <></>
      )}
    </MenuItem>
  );
}

function FieldsSideMenu() {
  const [context, setContext] = useContext(Context);
  const [acolumns, setAcolumns] = useState([]);
  const [search, setSearch] = useState(null);
  const { organizationId } = useParams();

  function onSearch(text) {
    setSearch(text);
  }

  //query tables
  const { data, loading, error } = useQuery(columns, {
    fetchPolicy: "cache-first",

    variables: {
      input:{
        "fromDttm": "",
        "toDttm": "",
        "rowLimit": 200,
        "rowOffset": 0,
        "filter": [],
        "table":context["f_context"]["table"],
        "group": "",
        "org": context["f_context"]["org"]
      },
       skip:context.f_context.org=="None",
    },
  });

  if (error) {
    console.log(error);
  }

  useEffect(() => {
    if (data) {
      setAcolumns(data.getColumns);
      setContext({ ...context, aColumns: data.getColumns });
    }
  }, [data]);

  return (
    <Box p={1} mt={-1.4} sx={{ backgroundColor: "#fafbfd", marginBottom:'9rem' }}>
      {organizationId ? <></> : <OrgSelect />}
      <Box m={1} sx={organizationId ?{ marginTop:'-.5rem'} :  {}} >
        <TextField
          label="search fields"
          variant="outlined"
          size="small"
          sx={{ width: "16.5rem" , '& .MuiOutlinedInput-root':{height:'2rem'} , '& .MuiOutlinedInput-input': {
            padding: '8.5px 14px',
            lineHeight: '1.5',
          },
            '& .MuiInputLabel-root':{top:'-3px'}
          }}
          onChange={(event) => {
            onSearch(event.target.value);
          }}
        />
      </Box>
      <Typography variant="subtitle2" color="secondary" sx={{marginTop:"1rem"}}>
        <b style={{ paddingLeft: "10px"}}>Selected Fields</b>
      </Typography>

      <Box m={1} sx={{ maxHeight: "auto" }}>
        <MenuList dense sx={{padding:'0px',marginTop:"-.5rem"}}>
          {context.sColumns.map((a, i) => {
            if (
              !search ||
              a.field.toLowerCase().indexOf(search?.toLowerCase()) !== -1
            ) {
              return <SMenuItem column={a} i={i} key={i} />;
            }
          })}
        </MenuList>
      </Box>

      <Typography variant="subtitle2" color="secondary">
        <b style={{ marginRight: "10px", paddingLeft: "10px" }}>
          Available Fields
        </b>
        {loading ? <CircularProgress size={10} /> : <></>}
      </Typography>

      <Box
        m={1}
        sx={{
          maxHeight: "auto",
          backgroundColor: "#e6f0f8",
          borderRadius: "10px",
        }}
      >
        <MenuList dense>
          {acolumns.filter((ac)=>ac.field!="event.ingested").map((a, i) => {
            if (!context.sColumns.map((c) => c.field).includes(a.field)) {
              if (
                !search ||
                a.field.toLowerCase().indexOf(search?.toLowerCase()) !== -1
              ) {
                return <AMenuItem key={i} column={a} />;
              }
            }
          })}
        </MenuList>
      </Box>
    </Box>
  );
}

export default FieldsSideMenu;
