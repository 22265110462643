import * as React from "react";
import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useQuery } from "@apollo/client";
import { roles } from "../query/query";
import { Context } from "../context/Context";
import keycloak from "../../keycloak";
import axios from "axios";

const url1=process.env.REACT_APP_AWS_KEYCLOAK_ENDPOINT
const url2=process.env.REACT_APP_AWS_ORGLIST_ENDPOINT

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, indexName, theme) {
  return {
    fontWeight:
      indexName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function OrgSelect() {
  const theme = useTheme();
  const [context, setContext] = React.useContext(Context);
  const [indexName, setIndexName] = useState(["All"]);
  const [defaultOrg, setDefaultOrg] = useState("");
  const [token, setToken] = useState("");
  const [orgCode, setOrgCode] = useState([]);
  const [apiToken, setApiToken] = useState("");
  const [orgNames, setOrgNames] = useState({});

  // Query roles
  const { data } = useQuery(roles, {
    fetchPolicy: "cache-first",
    variables: {
      input: "",
    },
  });

  useEffect(() => {
    if (token === "") {
      setToken(keycloak.token);
    }
  }, [token]);

  useEffect(() => {
    if (orgCode.length === 0 && data && data.getRoles) {
      const roles = data.getRoles;
      setOrgCode(roles);
    }
  }, [orgCode.length, data]);

  useEffect(() => {
    if (apiToken === "" && token !== "") {
      const fetchData = async () => {
        try {
          const response = await axios.post(
            url1,
            { access_token: token }
          );
          const { api_token, org_code } = response.data.data.data;
          setApiToken(api_token);
          setDefaultOrg(org_code);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      };
      fetchData();
    }
  }, [token, apiToken]);

  // Effect to fetch organization names
  useEffect(() => {
    if (orgCode.length > 0 && apiToken !== "") {
      const fetchData = async () => {
        try {
          const response = await axios.post(
            url2,
            {
              api_token: apiToken,
              params: {
                org_code: orgCode,
              },
            }
          );
          setOrgNames(response.data.data.data);
        } catch (error) {
          console.error("Error fetching data: ", error);
        }
      };
      fetchData();
    }
  }, [orgCode, apiToken]);

  useEffect(() => {
    if (context.f_context.org === "None") {
      setIndexName(["select Organization"]);
      // if (defaultOrg && orgNames[defaultOrg]) {
      //   setIndexName([orgNames[defaultOrg]]);
      //   setContext((prevContext) => ({
      //     ...prevContext,
      //     f_context: {
      //       ...prevContext.f_context,
      //       org: defaultOrg,
      //     },
      //   }));
      // }
    } else if (context.f_context.org !== "None" && context.f_context.org !== undefined) {
      const orgValue = orgNames[context.f_context.org];
      if (orgValue) {
        setIndexName([orgValue]);
      }
    }
  }, [context.f_context.org, defaultOrg, orgNames]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setIndexName(typeof value === "string" ? [value] : value);
    if (typeof value === "string") {
      const orgKey = Object.keys(orgNames).find(key => orgNames[key] === value);
      setContext({
        ...context,
        f_context: {
          ...context.f_context,
          org: orgKey,
        },
      });
    } else {
      const orgKey = Object.keys(orgNames).find(key => orgNames[key] === value[0]);
      setContext({
        ...context,
        f_context: {
          ...context.f_context,
          org: orgKey,
        },
      });
    }
  };

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: "15rem", marginTop: "-1rem" }} size="small">
        <InputLabel id="demo-multiple-chip-label" sx={{ color: "#11a1cd", position: "right" }}></InputLabel>
        <Select
          id="demo-multiple-chip"
          value={indexName}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" sx={{ height: "2rem", width: "16.5rem" }} />}
          renderValue={(selected) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
                whiteSpace: "nowrap",
                overflow: "hidden",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              {selected}
            </Box>
          )}
          MenuProps={MenuProps}
        // disabled={context.f_context.org !== null} // disable if context org is not null
        >
          {Object.entries(orgNames).map(([key, org]) => (
            <MenuItem key={key} value={org} style={getStyles(org, indexName, theme)}>
              {org}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}