import {
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Collapse,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import React, { useState, useEffect, useContext, useRef } from "react";
import { useQuery } from "@apollo/client";
import { logs, originalEvent } from "../query/query";
import { Context } from "../context/Context";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box } from "@mui/system";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import JsonView from "@uiw/react-json-view";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import InfiniteLoader from "react-infinite-loader";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { endOfDay, startOfDay, differenceInDays } from "date-fns";
import Position from "rsuite/esm/Overlay/Position";
import { json } from "react-router-dom";
import { UTCDate, toLocaleString } from "@date-fns/utc";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

// const minuteSeconds = 60;
// const hourSeconds = 300;
// const daySeconds = 86400;

// const timerProps = {
//   isPlaying: true,
//   size: 22,
//   strokeWidth: 4,
// };

// const renderTime = (dimension, time) => {
//   return (
//     <div className="time-wrapper">
//       <div className="time">{time}</div>
//       <div>{dimension}</div>
//     </div>
//   );
// };

// const getTimeSeconds = (time) => (minuteSeconds - time) | 0;
// const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
// const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
// const getTimeDays = (time) => (time / daySeconds) | 0;

// const stratTime = Date.now() / 1000; // use UNIX timestamp in seconds
// const endTime = stratTime + 243248; // use UNIX timestamp in seconds

// const remainingTime = endTime - stratTime;
// const days = Math.ceil(remainingTime / daySeconds);
// const daysDuration = days * daySeconds;
//till here

const flattenObj = (ob) => {
  let result = {};

  for (const i in ob) {
    if (typeof ob[i] === "object" && !Array.isArray(ob[i])) {
      const temp = flattenObj(ob[i]);
      for (const j in temp) {
        result[i + "." + j] = temp[j];
      }
    } else {
      result[i] = ob[i];
    }
  }

  return result;
};

function SourceField(log) {
  const temp = structuredClone(log);
  //  if (temp?.event?.created) {
  //   try {
  //     temp.event.created = temp.event.created.replace(" ", "T") + "Z";
  //   } catch {
  //     temp.event.created = temp.event.created.replace(" ", "T");
  //   }
  //   temp.event.created = new Date(temp.event.created).toLocaleString();
  // }
  if (temp?.event?.created) {
    let formattedDate = temp.event.created.replace(" ", "T");
    if (!formattedDate.endsWith("Z")) {
      formattedDate += "Z";
    }
    const isValidDate = (date) => !isNaN(Date.parse(date));
    if (isValidDate(formattedDate)) {
      temp.event.created = new Date(formattedDate).toLocaleString();
    } else {
      formattedDate = temp.event.created.replace(" ", "T");
      if (isValidDate(formattedDate)) {
        temp.event.created = new Date(formattedDate).toLocaleString();
      } else {
        temp.event.created = "Invalid Date";
      }
    }
  }

  if (temp?.alert?.created) {
    temp.alert.created = temp.alert.created.replace(" ", "T") + "Z";
    temp.alert.created = new Date(temp.alert.created).toLocaleString();
  }
  if (temp?.event?.start) {
    temp.event.start = temp.event.start.replace(" ", "T") + "Z";
    temp.event.start = new Date(temp.event.start).toLocaleString();
  }
  if (temp?.event?.timezone) {
    temp.event.timezone = temp.event.timezone.replace(" ", "T") + "Z";
    temp.event.timezone = new Date(temp.event.timezone).toLocaleString();
  }
  if (temp?.event?.end) {
    temp.event.end = temp.event.end.replace(" ", "T") + "Z";
    temp.event.end = new Date(temp.event.end).toLocaleString();
  }
  if (temp?.email?.delivery_timestamp) {
    temp.email.delivery_timestamp =
      temp.email.delivery_timestamp.replace(" ", "T") + "Z";
    temp.email.delivery_timestamp = new Date(
      temp.email.delivery_timestamp
    ).toLocaleString();
  }
  if (temp?.smtp?.first_received) {
    temp.smtp.first_received = temp.smtp.first_received.replace(" ", "T") + "Z";
    temp.smtp.first_received = new Date(
      temp.smtp.first_received
    ).toLocaleString();
  }
  if (temp?.file?.mtime) {
    temp.file.mtime = temp.file.mtime.replace(" ", "T") + "Z";
    temp.file.mtime = new Date(temp.file.mtime).toLocaleString();
  }
  if (temp?.file?.ctime) {
    temp.file.ctime = temp.file.ctime.replace(" ", "T") + "Z";
    temp.file.ctime = new Date(temp.file.ctime).toLocaleString();
  }
  if (temp?.file?.created) {
    temp.file.created = temp.file.created.replace(" ", "T") + "Z";
    temp.file.created = new Date(temp.file.created).toLocaleString();
  }
  if (temp?.dhcp?.lease_time) {
    temp.dhcp.lease_time = temp.dhcp.lease_time.replace(" ", "T") + "Z";
    temp.dhcp.lease_time = new Date(temp.dhcp.lease_time).toLocaleString();
  }
  if (temp?.netflow?.first_switched) {
    temp.netflow.first_switched =
      temp.netflow.first_switched.replace(" ", "T") + "Z";
    temp.netflow.first_switched = new Date(
      temp.netflow.first_switched
    ).toLocaleString();
  }
  if (temp?.threatintel?.malware?.timestamp) {
    temp.threatintel.malware.timestamp =
      temp.threatintel.malware.timestamp.replace(" ", "T") + "Z";
    temp.threatintel.malware.timestamp = new Date(
      temp.threatintel.malware.timestamp
    ).toLocaleString();
  }
  let flatten_log = flattenObj(temp);

  return (
    <>
      {Object.keys(flatten_log).map((one) => {
        return (
          <code
            style={{
              paddingLeft: ".3em",
              paddingRight: ".3em",
              lineHeight: 2,
              wordBreak: "break-all",
            }}
          >
            &nbsp;
            <mark
              style={{
                fontWeight: 400,
                borderRadius: "5px",
                backgroundColor: "#e5eef4",
              }}
            >
              {one.toString()}:
            </mark>
            {flatten_log[one].toString()}
          </code>
        );
      })}
    </>
  );
}

function getValue(log, column) {
  let keys = column.field.trim().split(".");
  let tempObj = log;

  if (column.field === "source") {
    return SourceField(log);
  }
  keys.forEach((element) => {
    if (tempObj) {
      if (tempObj[element]) {
        tempObj = tempObj[element];
      } else {
        tempObj = null;
      }
    }
  });

  if (column.type === "datetime" && tempObj !== null) {
    if (!tempObj.endsWith("Z")) {
      tempObj = tempObj.replace(" ", "T") + "Z";
    }

    return new Date(tempObj).toLocaleString();
  }

  return tempObj ? tempObj.toString() : null;
}

function TableActions(props) {
  const { visible, column, value } = props;
  const [context, setContext] = React.useContext(Context);

  const removeColumn = () => {
    const newColumns = context.sColumns.filter((a) => a !== column);
    setContext({ ...context, sColumns: newColumns });
  };

  const selectColumn = () => {
    if (!context.sColumns.includes(column)) {
      const newColumns = [...context.sColumns, column];
      setContext({ ...context, sColumns: newColumns });
    }
  };

  const addIsFilter = () => {
    if (Array.isArray(value)) {
      value.forEach((e) => {
        let uniq = "id" + new Date().getTime();
        let filter = {
          Id: uniq,
          field: column.field,
          dtype: column.type,
          op: "IS",
          value: e,
          status: "active",
        };

        setContext({
          ...context,
          f_context: {
            ...context.f_context,
            filter: [...context.f_context.filter, filter],
          },
        });
      });
    } else {
      let uniq = "id" + new Date().getTime();
      let filter = {
        Id: uniq,
        field: column.field,
        dtype: column.type,
        op: "IS",
        value: value,
        status: "active",
      };

      setContext({
        ...context,
        f_context: {
          ...context.f_context,
          filter: [...context.f_context.filter, filter],
        },
      });
    }
  };

  const addIsNotFilter = () => {
    if (Array.isArray(value)) {
      value.forEach((e) => {
        let uniq = "id" + new Date().getTime();
        let filter = {
          Id: uniq,
          field: column.field,
          dtype: column.type,
          op: "IS NOT",
          value: e,
          status: "active",
        };

        setContext({
          ...context,
          f_context: {
            ...context.f_context,
            filter: [...context.f_context.filter, filter],
          },
        });
      });
    } else {
      let uniq = "id" + new Date().getTime();
      let filter = {
        Id: uniq,
        field: column.field,
        dtype: column.type,
        op: "IS NOT",
        value: value,
        status: "active",
      };

      setContext({
        ...context,
        f_context: {
          ...context.f_context,
          filter: [...context.f_context.filter, filter],
        },
      });
    }
  };

  if (
    visible &&
    column.type !== "datetime" &&
    column.type !== "json" &&
    column.field !== "organization.id"
  ) {
    return (
      <code>
        <Tooltip title="Include Value">
          <IconButton size="small" onClick={addIsFilter}>
            <ZoomInIcon fontSize="2px" color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Exclude Value">
          <IconButton size="small" onClick={addIsNotFilter}>
            <ZoomOutIcon fontSize="2px" color="error" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Add as Column">
          <IconButton size="small" onClick={selectColumn}>
            <PlaylistAddIcon fontSize="2px" color="primary" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Remove Column">
          <IconButton size="small" onClick={removeColumn}>
            <PlaylistRemoveIcon fontSize="2px" color="error" />
          </IconButton>
        </Tooltip>
      </code>
    );
  } else {
    return <Stack direction="row"></Stack>;
  }
}

function ChildTableRow(props) {
  const { a, value } = props;
  const [actions, setActions] = useState(false);

  const onmouseover = () => {
    setActions(true);
  };

  const onmouseleave = () => {
    setActions(false);
  };

  return (
    <TableRow onMouseEnter={onmouseover} onMouseLeave={onmouseleave}>
      <TableCell width={"30em"} sx={{ width: "12em" }}>
        <TableActions visible={actions} column={a} value={value} />
      </TableCell>
      <TableCell width={"5em"}>
        <code>{a.field}</code>
      </TableCell>
      <TableCell>
        <Stack direction="row" spacing={2}>
          <code>{value}</code>
        </Stack>
      </TableCell>
    </TableRow>
  );
}

function OriginalEvent(props) {
  const [context, setContext] = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const { data, loading, error, fetchMore, refetch } = useQuery(originalEvent, {
    fetchPolicy: "cache-first",

    variables: {
      input: {
        uuid: props.value.uuid,
        org: props.value.organization.id,
        fromDttm: props.value.event.created,
        table: context.f_context.table,
      },
    },
  });

  const copyToClipboard = () => {
    if (data && data.getRawData) {
      const textField = document.createElement("textarea");
      textField.innerText = data.getRawData; // Copy the original event data
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();
      setOpen(true); // Show Snackbar on success
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  return (
    <Box style={{ width: "72vw", position: "relative" }}>
      {loading ? (
        <code
          style={{
            fontSize: ".9em",
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#f5f7fa",
              padding: 1,
              borderRadius: "10px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <span style={{ alignContent: "center" }}>
              {" "}
              Loading Original Event please wait...
            </span>

            {/* <CountdownCircleTimer
              {...timerProps}
              colors="#11a1cd"
              duration={hourSeconds}
              initialRemainingTime={remainingTime % hourSeconds}
              onComplete={(totalElapsedTime) => ({
                shouldRepeat: remainingTime - totalElapsedTime > minuteSeconds,
              })}
            > */}
            {/* {({ elapsedTime, color }) => (
                <span style={{ color }}>
                  {renderTime(
                    "minutes",
                    getTimeMinutes(hourSeconds - elapsedTime)
                  )}
                </span>
              )} */}
            {/* </CountdownCircleTimer> */}
            <div>
              <CountdownCircleTimer
                isPlaying
                duration={300}
                size={45}
                strokeWidth={3}
                colors="#11a1cd"
              >
                {({ remainingTime }) => {
                  const minutes = Math.floor(remainingTime / 60);
                  const seconds = remainingTime % 60;
                  return (
                    <div className="timer">
                      <div
                        style={{ fontSize: "10px", marginRight: "0rem" }}
                        className="value"
                      >{`${minutes}:${
                        seconds < 10 ? `0${seconds}` : seconds
                      }`}</div>
                      {/* <div className="text">minutes</div> */}
                    </div>
                  );
                }}
              </CountdownCircleTimer>
            </div>
          </Box>
        </code>
      ) : (
        <Box
          sx={{
            backgroundColor: "#f5f7fa",
            borderRadius: "10px",
            padding: 2,
            paddingRight: "3em",
            position: "relative",
          }}
        >
          <code
            style={{
              fontSize: ".7em",
              textOverflow: "ellipsis",
              overflow: "hidden",
              wordWrap: "break-word",
            }}
          >
            {data ? data.getRawData : "Something went wrong .."}
          </code>
          <button
            className="copy-button"
            onClick={copyToClipboard}
            style={{
              position: "absolute",
              right: "5px",
              top: "5px",
              backgroundColor: "#f5f7fa",
              height: "2.2em",
              margin: "10px",
              borderRadius: "10px",
              cursor: "pointer",
            }}
          >
            <CopyAllIcon sx={{ padding: "3px" }} />
          </button>
          <Snackbar
            open={open}
            autoHideDuration={1000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert onClose={handleClose} severity="success">
              Copied to clipboard!
            </Alert>
          </Snackbar>
        </Box>
      )}
    </Box>
  );
}

function ChildTable(props) {
  const [context, setContext] = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);
  const { log_obj } = props;
  // const temp = {...log_obj};
  const temp = log_obj;

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              <b>
                {" "}
                <code>Field</code>
              </b>
            </TableCell>
            <TableCell>
              <b>
                <code>Value</code>
              </b>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {context.aColumns
            .slice()
            .sort((a, b) => a.field.localeCompare(b.field))
            .map((a, i) => {
              if (a.field !== "source" && a.field !== "pdate") {
                let value = getValue(temp, a);
                if (value) {
                  return <ChildTableRow a={a} value={value} key={i} />;
                }
              }
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function RowTabs(props) {
  const [value, setValue] = React.useState("1");
  const [isLoading, setIsLoading] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const temp = structuredClone(props.log_obj);
  //  if (temp?.event?.created) {
  //   try {
  //     temp.event.created = temp.event.created.replace(" ", "T") + "Z";
  //   } catch {
  //     temp.event.created = temp.event.created.replace(" ", "T");
  //   }
  //   temp.event.created = new Date(temp.event.created).toLocaleString();
  // }
  if (temp?.event?.created) {
    let formattedDate = temp.event.created.replace(" ", "T");
    if (!formattedDate.endsWith("Z")) {
      formattedDate += "Z";
    }
    const isValidDate = (date) => !isNaN(Date.parse(date));
    if (isValidDate(formattedDate)) {
      temp.event.created = new Date(formattedDate).toLocaleString();
    } else {
      formattedDate = temp.event.created.replace(" ", "T");
      if (isValidDate(formattedDate)) {
        temp.event.created = new Date(formattedDate).toLocaleString();
      } else {
        temp.event.created = "Invalid Date";
      }
    }
  }

  if (temp?.alert?.created) {
    temp.alert.created = temp.alert.created.replace(" ", "T") + "Z";
    temp.alert.created = new Date(temp.alert.created).toLocaleString();
  }
  if (temp?.event?.start) {
    temp.event.start = temp.event.start.replace(" ", "T") + "Z";
    temp.event.start = new Date(temp.event.start).toLocaleString();
  }
  if (temp?.event?.timezone) {
    temp.event.timezone = temp.event.timezone.replace(" ", "T") + "Z";
    temp.event.timezone = new Date(temp.event.timezone).toLocaleString();
  }
  if (temp?.event?.end) {
    temp.event.end = temp.event.end.replace(" ", "T") + "Z";
    temp.event.end = new Date(temp.event.end).toLocaleString();
  }
  if (temp?.email?.delivery_timestamp) {
    temp.email.delivery_timestamp =
      temp.email.delivery_timestamp.replace(" ", "T") + "Z";
    temp.email.delivery_timestamp = new Date(
      temp.email.delivery_timestamp
    ).toLocaleString();
  }
  if (temp?.smtp?.first_received) {
    temp.smtp.first_received = temp.smtp.first_received.replace(" ", "T") + "Z";
    temp.smtp.first_received = new Date(
      temp.smtp.first_received
    ).toLocaleString();
  }
  if (temp?.file?.mtime) {
    temp.file.mtime = temp.file.mtime.replace(" ", "T") + "Z";
    temp.file.mtime = new Date(temp.file.mtime).toLocaleString();
  }
  if (temp?.file?.ctime) {
    temp.file.ctime = temp.file.ctime.replace(" ", "T") + "Z";
    temp.file.ctime = new Date(temp.file.ctime).toLocaleString();
  }
  if (temp?.file?.created) {
    temp.file.created = temp.file.created.replace(" ", "T") + "Z";
    temp.file.created = new Date(temp.file.created).toLocaleString();
  }
  if (temp?.dhcp?.lease_time) {
    temp.dhcp.lease_time = temp.dhcp.lease_time.replace(" ", "T") + "Z";
    temp.dhcp.lease_time = new Date(temp.dhcp.lease_time).toLocaleString();
  }
  if (temp?.netflow?.first_switched) {
    temp.netflow.first_switched =
      temp.netflow.first_switched.replace(" ", "T") + "Z";
    temp.netflow.first_switched = new Date(
      temp.netflow.first_switched
    ).toLocaleString();
  }
  if (temp?.threatintel?.malware?.timestamp) {
    temp.threatintel.malware.timestamp =
      temp.threatintel.malware.timestamp.replace(" ", "T") + "Z";
    temp.threatintel.malware.timestamp = new Date(
      temp.threatintel.malware.timestamp
    ).toLocaleString();
  }

  const [open, setOpen] = useState(false);
  const copyToClipboard = () => {
    // navigator.clipboard
    //   .writeText(JSON.stringify(props.log_obj, null, 2))
    //   .then(() => {
    //     setOpen(true);
    //   })
    //   .catch((err) => {
    //     console.error("Failed to copy: ", err);
    //   });
    var textField = document.createElement("textarea");
    textField.innerText = JSON.stringify(props.log_obj, null, 2);
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <Box
      sx={{ width: "100%", typography: "body1", maxWidth: "80vw !important" }}
    >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Table" value="1" />
            <Tab label="Json" value="2" />
            <Tab label="Original Event" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Box>
            <ChildTable log_obj={temp} />
          </Box>
        </TabPanel>
        <TabPanel value="2">
          <Box
            sx={{
              // position: 'relative',
              backgroundColor: "#f5f7fa",
              borderRadius: "6px",
              width: "73vw",
            }}
          >
            <div
              className="json-view-container"
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "row",
                Width: "12em",
              }}
            >
              <JsonView
                value={temp}
                displayDataTypes={false}
                enableClipboard={false}
                displayObjectSize={false}
                collapsed={10}
                shortenTextAfterLength={1000}
                style={{ minHeight: "3.4rem" }}
              />
              <div>
                <button
                  className="copy-button"
                  onClick={copyToClipboard}
                  style={{
                    position: "absolute",
                    right: "1px",
                    top: "1px",
                    backgroundColor: "#f5f7fa",
                    height: "2.5em",
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <CopyAllIcon sx={{ padding: "1px" }} />
                </button>
                <Snackbar
                  open={open}
                  autoHideDuration={1000}
                  onClose={handleClose}
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                >
                  <Alert onClose={handleClose} severity="success">
                    Copied to clipboard!
                  </Alert>
                </Snackbar>
              </div>
            </div>
          </Box>
        </TabPanel>
        <TabPanel value="3">
          <Box>
            <OriginalEvent value={props.log_obj} />
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
}

function Row(props) {
  const [context, setContext] = useContext(Context);
  const [open, setOpen] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let log_obj = JSON.parse(props.log);

  delete log_obj.event.ingested;

  return (
    <>
      <TableRow sx={{ maxHeight: "3em" }} key={props.r}>
        <TableCell sx={{ paddingLeft: "0px", paddingRight: "0px" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>

        {context.sColumns.map((column, i) => {
          if (column.field !== "source") {
            return (
              <TableCell
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  padding: "1em",
                }}
                key={i}
              >
                <code
                  style={{
                    fontSize: ".9em",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {getValue(log_obj, column)}
                </code>
              </TableCell>
            );
          } else if (context.sColumns.length < 3) {
            return (
              <TableCell
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  padding: "1em",
                }}
                key={i}
              >
                <code
                  style={{
                    fontSize: ".9em",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                  }}
                >
                  {getValue(log_obj, column)}
                </code>
              </TableCell>
            );
          }
        })}
      </TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box>
            <RowTabs log_obj={log_obj} />
          </Box>
        </Collapse>
      </TableCell>
    </>
  );
}

function TableHeadCell(props) {
  const [context, setContext] = useContext(Context);
  const [visible, setVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { column, i } = props;
  const removeColumn = () => {
    const newColumns = context.sColumns.filter((a) => a !== column);
    setContext({ ...context, sColumns: newColumns });
  };

  const moveLeft = () => {
    if (i !== 0) {
      let columns = context.sColumns;
      let temp = columns[i];
      columns[i] = columns[i - 1];
      columns[i - 1] = temp;
      setContext({ ...context, sColumns: columns });
    }
  };
  const moveRight = () => {
    if (i !== context.sColumns.length - 1) {
      let columns = context.sColumns;
      let temp = columns[i];
      columns[i] = columns[i + 1];
      columns[i + 1] = temp;
      setContext({ ...context, sColumns: columns });
    }
  };
  return (
    <TableCell
      sx={{ minWidth: "12rem" }}
      onMouseEnter={() => {
        setVisible(true);
      }}
      onMouseLeave={() => {
        setVisible(false);
      }}
    >
      <code>
        {" "}
        <b>{column.field === "source" ? "event" : column.field}</b>
        {visible &&
        column.field !== "event.created" &&
        column.field !== "alert.created" &&
        column.field !== "source" ? (
          <>
            <Tooltip title="Remove Column">
              <IconButton size="small" onClick={removeColumn}>
                <PlaylistRemoveIcon fontSize="2px" color="error" />
              </IconButton>
            </Tooltip>

            <Tooltip title="Move Left">
              <IconButton size="small" onClick={moveLeft}>
                <ChevronLeftIcon fontSize="2px" color="primary" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Move Right">
              <IconButton size="small" onClick={moveRight}>
                <ChevronRightIcon fontSize="2px" color="primary" />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <></>
        )}
      </code>
    </TableCell>
  );
}

const CustomScrollbar = ({
  width,
  height,
  scrollWidth,
  scrollHeight,
  onScroll,
  containerRef,
  orientation = "horizontal",
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [startPosition, setStartPosition] = useState(0);
  const [thumbPosition, setThumbPosition] = useState(0);
  const thumbRef = useRef(null);
  const trackRef = useRef(null);

  const isHorizontal = orientation === "horizontal";

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);
    setStartPosition(
      isHorizontal
        ? e.clientX - thumbRef.current.getBoundingClientRect().left
        : e.clientY - thumbRef.current.getBoundingClientRect().top
    );
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const trackRect = trackRef.current.getBoundingClientRect();
    const newPosition = isHorizontal
      ? e.clientX - trackRect.left
      : e.clientY - trackRect.top;
    const scrollSize = isHorizontal ? scrollWidth : scrollHeight;
    const viewportSize = isHorizontal ? width : height;
    const newScrollPosition =
      (newPosition - startPosition) / (viewportSize / scrollSize);
    onScroll(
      Math.max(0, Math.min(newScrollPosition, scrollSize - viewportSize))
    );
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTrackClick = (e) => {
    const trackRect = trackRef.current.getBoundingClientRect();
    const clickPosition = isHorizontal
      ? e.clientX - trackRect.left
      : e.clientY - trackRect.top;
    const trackSize = isHorizontal ? trackRect.width : trackRect.height;
    const scrollSize = isHorizontal ? scrollWidth : scrollHeight;
    const viewportSize = isHorizontal ? width : height;
    const scrollPosition =
      (clickPosition / trackSize) * (scrollSize - viewportSize);
    onScroll(scrollPosition);
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, startPosition, width, height, scrollWidth, scrollHeight]);

  useEffect(() => {
    const updateThumbPosition = () => {
      if (containerRef.current) {
        const scrollPosition = isHorizontal
          ? containerRef.current.scrollLeft
          : containerRef.current.scrollTop;
        const scrollSize = isHorizontal ? scrollWidth : scrollHeight;
        const viewportSize = isHorizontal ? width : height;
        const scrollPercent = scrollPosition / (scrollSize - viewportSize);
        const maxThumbPosition = viewportSize - thumbSize;
        setThumbPosition(scrollPercent * maxThumbPosition);
      }
    };

    updateThumbPosition();
    containerRef.current?.addEventListener("scroll", updateThumbPosition);
    return () =>
      containerRef.current?.removeEventListener("scroll", updateThumbPosition);
  }, [width, height, scrollWidth, scrollHeight]);

  const thumbSize = Math.max(
    ((isHorizontal ? width : height) /
      (isHorizontal ? scrollWidth : scrollHeight)) *
      (isHorizontal ? width : height),
    20
  );

  return (
    <Box
      ref={trackRef}
      onClick={handleTrackClick}
      sx={{
        width: isHorizontal ? "100%" : "7px",
        height: isHorizontal ? "7px" : "100%",
        backgroundColor: "#f0f0f0",
        position: "relative",
        borderRadius: "5px",
        cursor: "pointer",
      }}
    >
      <Box
        ref={thumbRef}
        sx={{
          width: isHorizontal ? `${thumbSize}px` : "100%",
          height: isHorizontal ? "100%" : `${thumbSize}px`,
          backgroundColor: "#bbb",
          position: "absolute",
          left: isHorizontal ? `${thumbPosition}px` : 0,
          top: isHorizontal ? 0 : `${thumbPosition}px`,
          borderRadius: "5px",
          cursor: "grab",
          "&:active": {
            cursor: "grabbing",
          },
        }}
        onMouseDown={handleMouseDown}
      />
    </Box>
  );
};

function LogsTable(props) {
  const [context, setContext] = useContext(Context);
  const [offset, setOffset] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef(null);
  const [retry, setRetry] = useState(false);
  const [containerWidth, setContainerWidth] = useState(0);
  const [contentWidth, setContentWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);
  const [showHorizontalScrollbar, setShowHorizontalScrollbar] = useState(false);
  const [showVerticalScrollbar, setShowVerticalScrollbar] = useState(false);

  const handleHorizontalScroll = (scrollLeft) => {
    if (containerRef.current) {
      containerRef.current.scrollLeft = scrollLeft;
    }
  };

  const handleVerticalScroll = (scrollTop) => {
    if (containerRef.current) {
      containerRef.current.scrollTop = scrollTop;
    }
  };

  // const [inputdata, setinputdata] = useState({
  //   ...context["f_context"],
  //   toDttm: context["f_context"]["toDttm"],
  //   fromDttm:
  //     differenceInDays(
  //       new Date(context["f_context"]["toDttm"]),
  //       new Date(context["f_context"]["fromDttm"])
  //     ) > 0
  //       ? startOfDay(new Date(context["f_context"]["toDttm"])).toISOString()
  //       : context["f_context"]["fromDttm"],
  // });

  // useEffect(() => {
  //   setinputdata({
  //     ...context["f_context"],
  //     toDttm: context["f_context"]["toDttm"],
  //     fromDttm:
  //       differenceInDays(
  //         new Date(context["f_context"]["toDttm"]),
  //         new Date(context["f_context"]["fromDttm"])
  //       ) > 0
  //         ? startOfDay(new Date(context["f_context"]["toDttm"])).toISOString()
  //         : context["f_context"]["fromDttm"],
  //   });
  // }, [context]);

  //query tables
  const { data, loading, error, fetchMore, refetch } = useQuery(logs, {
    fetchPolicy: "cache-first",

    variables: {
      // input: inputdata,

      input: {
        ...context["f_context"],
        fromDttm: context["f_context"]["fromDttm"],
        toDttm: context["f_context"]["toDttm"],
        // toDttm: context["refactorDttm"]!==""?context["refactorDttm"]:context["f_context"]["toDttm"],
      },
    },
    skip: context.f_context.org == "None",
    onCompleted: () => {
      setIsLoading(false);
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const newContainerWidth = containerRef.current.clientWidth;
        const newContentWidth = containerRef.current.scrollWidth;
        const newContainerHeight = containerRef.current.clientHeight;
        const newContentHeight = containerRef.current.scrollHeight;

        setContainerWidth(newContainerWidth);
        setContentWidth(newContentWidth);
        setContainerHeight(newContainerHeight);
        setContentHeight(newContentHeight);
        setShowHorizontalScrollbar(newContentWidth > newContainerWidth);
        setShowVerticalScrollbar(
          newContentHeight > newContainerHeight || data?.getLogs.length > 5
        );
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    const observer = new MutationObserver(updateDimensions);
    if (containerRef.current) {
      observer.observe(containerRef.current, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      window.removeEventListener("resize", updateDimensions);
      observer.disconnect();
    };
  }, [context.sColumns, data]);

  const handleScroll = () => {
    if (containerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
      if (scrollHeight - scrollTop <= clientHeight * 1.5) {
        loadMore();
      }
    }
  };


  const loadMore = () => {
    if (data?.getLogs.length >= 200 && offset < 10000) {
      fetchMore({
        variables: {
          input: {
            ...context["f_context"],
            rowOffset: offset + 100,
          },
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) {
            setOffset(10000);
            return prev;
          }
          setOffset(offset + 100);
          return Object.assign({}, prev, {
            getLogs: [...prev.getLogs, ...fetchMoreResult.getLogs],
          });
        },
      });
    }
  };

   useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener('scroll', handleScroll);
    }
    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, [offset, data]);

  useEffect(() => {
    if (data?.getLogs.length === 0) {
      refetch({
        input: {
          ...context["f_context"],
          fromDttm: context["f_context"]["fromDttm"],
          toDttm: context["refactorDttm"],
        },
      });
    }
  }, [data, context]);

  useEffect(() => {
    if (context["refetchQs"]) {
      handleRefresh();
    }
  }, [context["refetchQs"]]);

  const handleRefresh = async () => {
    setIsLoading(true);
    await refetch();
    setIsLoading(false);
    setContext({
      ...context,
      refetchQs: false,
    });
  };

  if (isLoading || loading) {
    return (
      <Box
        sx={{ display: "flex", justifyContent: "center", marginTop: "5rem" }}
      >
        <div style={{ position: "relative" }}>
          <span
            style={{
              position: "absolute",
              top: "24px",
              left: "12px",
              color: "#2092bb",
              fontSize: 13,
            }}
          >
            Loading
          </span>
          <CircularProgress size={70} />
        </div>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: "calc(100vh - 360px)",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <Box
        ref={containerRef}
        sx={{
          maxHeight: showHorizontalScrollbar ? "calc(100% - 10px)" : "100%",
          overflowY: "auto",
          overflowX: "auto",
          "&::-webkit-scrollbar": { width: "0px", height: "0px" },
          scrollbarWidth: "none",
          marginRight: showVerticalScrollbar ? "8px" : "0px",
        }}
      >
        <TableContainer sx={{ maxHeight: "none", overflow: "visible" }}>
          <Table
            className="tab"
            stickyHeader
            aria-label="sticky table"
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {context.sColumns.map((column, i) => {
                  if (column.field !== "source") {
                    return <TableHeadCell key={i} column={column} i={i} />;
                  } else if (context.sColumns.length < 3) {
                    return <TableHeadCell key={i} column={column} i={i} />;
                  }
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {!context.f_context.orderbyflag &&
                Array.isArray(data?.getLogs) &&
                [...data.getLogs]
                  .sort(
                    (a, b) =>
                      new Date(JSON.parse(b).event.created) -
                      new Date(JSON.parse(a).event.created)
                  )
                  .map((log, r) => {
                    return <Row log={log} key={r} r={r} />;
                  })}

              {context.f_context.orderbyflag &&
                data?.getLogs.map((log, r) => {
                  return <Row log={log} key={r} r={r} />;
                })}
            </TableBody>
          </Table>
          {data?.getLogs.length >= 200 && offset < 10000 ? (
            <>
              <CircularProgress />

              <InfiniteLoader
                onVisited={() => {
                  if (offset < 10000) {
                    fetchMore({
                      variables: {
                        input: {
                          ...context["f_context"],
                          rowOffset: offset + 100,
                        },
                      },
                      updateQuery: (prev, { fetchMoreResult }) => {
                        if (!fetchMoreResult) {
                          setOffset(10000);

                          return prev;
                        }
                        setOffset(offset + 100);
                        return Object.assign({}, prev, {
                          getLogs: [
                            ...prev.getLogs,
                            ...fetchMoreResult.getLogs,
                          ],
                        });
                      },
                    });
                  }
                }}
              ></InfiniteLoader>
            </>
          ) : (
            <></>
          )}
        </TableContainer>
      </Box>
      {showHorizontalScrollbar && (
        <Box sx={{ paddingRight: showVerticalScrollbar ? "10px" : "0px" }}>
          <CustomScrollbar
            width={containerWidth}
            scrollWidth={contentWidth}
            onScroll={handleHorizontalScroll}
            containerRef={containerRef}
            orientation="horizontal"
          />
        </Box>
      )}
      {showVerticalScrollbar && (
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            bottom: showHorizontalScrollbar ? "10px" : "0px",
          }}
        >
          <CustomScrollbar
            height={containerHeight}
            scrollHeight={contentHeight}
            onScroll={handleVerticalScroll}
            containerRef={containerRef}
            orientation="vertical"
          />
        </Box>
      )}
    </Box>
  );
}

export default LogsTable;
