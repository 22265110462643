import {
  Dialog,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  Grid,
  Paper,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import { Context } from "../context/Context";
import React, { useState } from "react";
import { makeStyles } from "@mui/styles";
import AdbIcon from "@mui/icons-material/Adb";
import Popover from "@mui/material/Popover";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import Button from "@mui/material/Button";
import { useLazyQuery } from "@apollo/client";
import { openai } from "../query/query";
import { Clear, ColorizeSharp } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import { LoadingButton } from "@mui/lab";
import {
  addMinutes,
  addHours,
  addDays,
  addMonths,
  differenceInHours,
} from "date-fns";
import { UTCDate } from "@date-fns/utc";

function OpenAi() {
  const [text, setText] = useState('');
  const [querytext, setQueryText] = useState(null);
  const [context, setContext] = React.useContext(Context);

  function getGroup(fromDttm, toDttm) {
    let group = "minute";
    let diff = differenceInHours(toDttm, fromDttm);

    if (diff > 2 && diff < 49) {
      group = "hour";
    } else if (diff > 48) {
      group = "day";
    }
    return group;
  }

  function ExtractTimeFilters(duration, duration_type) {
    var num = parseInt(duration);
    switch (duration_type) {
      case "minute":
        return [
          addMinutes(new Date(), -num).toISOString(),
          new Date().toISOString(),
          getGroup(addMinutes(new Date(), -num), new Date()),
        ];
        break;
      case "hour":
        return [
          addHours(new Date(), -num).toISOString(),
          new Date().toISOString(),
          getGroup(addHours(new Date(), -num), new Date()),
        ];
        break;

      case "day":
        return [
          addDays(new Date(), -num).toISOString(),
          new Date().toISOString(),
          getGroup(addDays(new Date(), -num), new Date()),
        ];
        break;

      case "week":
        return [
          addDays(new Date(), -num * 7).toISOString(),
          new Date().toISOString(),
          getGroup(addDays(new Date(), -num * 7), new Date()),
        ];
        break;

      case "month":
        return [
          addMonths(new Date(), -num).toISOString(),
          new Date().toISOString(),
          getGroup(addMonths(new Date(), -num), new Date()),
        ];
        break;

      default:
        return ["NA", "NA", "NA"];
    }
  }

  function getFiledType(column) {
    let acolumn = context.aColumns.filter((c) => c.field === column);

    if (acolumn.length > 0) {
      return acolumn[0].type;
    }

    return "varchar(16)";
  }

  function getFilter(one) {
    var filed = one["field"];
    var field_type = getFiledType(filed);
    var op = one["OP"];
    var value = one["value"].join("#@#");
    var status = one["status"];

    return {
      Id: "id" + new Date().getTime() + Math.floor(Math.random() * 99),
      field: filed,
      dtype: field_type,
      op: op,
      value: value,
      status: status
    };
  }

  function parseFilters(raw_filtes) {
    let filters = [];
    for (let i = 0; i < raw_filtes.length; i++) {
      try {
        filters = [...filters, getFilter(raw_filtes[i])];
      } catch (error) { }
    }

    return filters;
  }

  function removeTimeFilters(filters) {
    function filterDate(obj) {
      return obj.field !== "event.created" && obj.field !== "@timestamp";
    }

    return filters.filter(filterDate);
  }

  const useStyles = makeStyles((theme) => ({
    popper: {},
    fab: {},
    popover: {},
  }));

  const [getAIQuery, { loading, data, error }] = useLazyQuery(openai, {
    variables: {
      input: { text: querytext },
    },
  });

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  const handleChange = (event) => {
    setText(event.target.value);
  };

  const handleFabClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(!open);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    if (data) {
      try {
        let ai_filters = JSON.parse(data["getAIQuery"])["choices"][0][
          "message"
        ]["content"];
        ai_filters = JSON.parse(ai_filters.replaceAll("'", '"'));

        let [fromDttm, toDttm, group] = ExtractTimeFilters(
          ai_filters["duration"],
          ai_filters["duration_type"]
        );
        let status = "active";
        ai_filters["filter"] = ai_filters["filter"].map(obj => {
          return { ...obj, status: status };
        });
        let raw_filters_list = ai_filters["filter"];

        let filters = parseFilters(raw_filters_list);

        if (fromDttm !== "NA") {
          setContext({
            ...context,
            f_context: {
              ...context.f_context,
              fromDttm: fromDttm,
              toDttm: toDttm,
              group: group,
              filter: removeTimeFilters(filters),
            },
          });
        } else {
          setContext({
            ...context,
            f_context: {
              ...context.f_context,
              filter: removeTimeFilters(filters),
            },
          });
        }
      } catch (error) {
        console.log(error);
        //alert("Apologies, but I'm currently unable to process that request. My capabilities are continually evolving, and there are certain queries or tasks that might be beyond my current scope. If you have another question , feel free to ask, and I'll do my best to help")
      }
    }
  }, [data]);

  if (error) {
    console.log(error);
  }
  if (data) {
  }

  return (
    <div className={classes.popper} style={{ zIndex: 100 }}>
      <Paper
        elevation={0}
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          marginLeft: "-.4rem",
          backgroundColor: "#fafbfd"
        }}
      >
        <TextField
          size="small"
          sx={{ width: "52vw", backgroundColor: 'white', "& .MuiOutlinedInput-input": { height: '.8rem'} ,borderRadius:'4px','& .MuiInputBase-input::placeholder': {fontSize: '14px'}}}
          placeholder="Blusapphire AI Assistant (Beta)"
          fullWidth
          value={text}
          onChange={handleChange}
          onKeyDown={(e) => {
            if (e.which === 13 || e.keyCode === 13) {
              e.preventDefault();
              setQueryText(text);
              getAIQuery();
            }
          }}
          InputProps={{
            endAdornment:(
              <IconButton
               aria-label="clear input"
               onClick={() => setText('')}
               size="small"
               sx={{padding:'6px',marginRight:'-1rem'}}
               >
                <Clear sx={{height:'1rem',color:'lightgrey'}}/>
               </IconButton>
            )
          }}
        />

        <LoadingButton
          loading={loading}
          variant="contained"
          sx={{ m: "10px", textTransform: 'None', fontWeight: '550', maxHeight: '1.8rem' }}
          onClick={() => {
            setQueryText(text);
            getAIQuery();
          }}
        >
          {"Search"}
        </LoadingButton>
      </Paper>
    </div>
  );
}

export default OpenAi;
