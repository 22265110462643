import { Grid, LinearProgress } from "@mui/material"
import { Box } from "@mui/system"




  
function MainLoading()
{


    return (
        <Box sx={{marginLeft:'40%',marginTop:'15%'}}> 
        <Grid container spacing={1}>
        <Grid item xs={12}>
            <Box p={1}>
            <img src={process.env.REACT_APP_BLUSAPPHIRE_LOGO} height='50em'/>
            </Box>
        
        </Grid>
        <Grid item xs={4}>
            <LinearProgress/>
        </Grid>

        </Grid>



        </Box>
      
    )
}


export default MainLoading