import { Box } from "@mui/system";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { Context } from "../context/Context";
import React, { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
// import Filter from "../filter/Filter";
// import ClearFilter from "../filter/ClearFilter";
// import subDays from "date-fns/subDays";
// import startOfWeek from "date-fns/startOfWeek";
// import endOfWeek from "date-fns/endOfWeek";
// import startOfMonth from "date-fns/startOfMonth";
// import endOfMonth from "date-fns/endOfMonth";
// import { IconButton } from "@mui/material";

import {
  addMinutes,
  addDays,
  addMonths,
  differenceInHours,
} from "date-fns";
import { UTCDate } from "@date-fns/utc";
// import SaveFilter from "../filter/SaveFilter";
// import Export from "../logs/Export";
// import OpenContext from "../filter/OpenContext";
// import OpenAi from "../filter/OpenAi";

const predefinedRanges = [
  {
    label: "Last 15 minutes",
    value: (value) => {
      return [addMinutes(new Date(), -15), new Date()];
    },
    placement: "left",
  },
  {
    label: "Last 30 minutes",
    value: (value) => {
      return [addMinutes(new UTCDate(), -30), new UTCDate()];
    },
    placement: "left",
  },
  {
    label: "Last 1 hour",
    value: (value) => {
      return [addMinutes(new UTCDate(), -60), new UTCDate()];
    },
    placement: "left",
  },
  {
    label: "Last 24 hours",
    value: (value) => {
      return [addMinutes(new UTCDate(), -60 * 24), new UTCDate()];
    },
    placement: "left",
  },
  {
    label: "Last 7 days",
    value: (value) => {
      return [addDays(new UTCDate(), -7), new UTCDate()];
    },
    placement: "left",
  },
  {
    label: "Last 1 Month",
    value: (value) => {
      return [addMonths(new UTCDate(), -1), new UTCDate()];
    },
    placement: "left",
  },
  {
    label: "Last 3 Months",
    value: (value) => {
      return [addMonths(new UTCDate(), -3), new UTCDate()];
    },
    placement: "left",
  },
];

function TimeRangePicker(props) {
  const [context, setContext] = React.useContext(Context);
  const [value, setvalue] = useState({});
  const { afterToday } = DateRangePicker;
  useEffect(() => {
    setvalue({
      fromDttm: context["f_context"]["fromDttm"],
      toDttm: context["f_context"]["toDttm"],
      group: context["f_context"]["group"],
    });
  }, []);
  return (
    <Box sx={{ position: "fixed", right: "0em", width: "45rem" }}>
      <Grid container>
        <Grid item></Grid>
        <Grid item pl={30} ml={2}>
          <DateRangePicker
            size="sm"
            // menuAutoWidth
            shouldDisableDate={afterToday()}
            placement="bottomEnd"
            value={[new Date(context.f_context.fromDttm), new Date(context.f_context.toDttm)]}
            ranges={predefinedRanges}
            format="yyyy-MM-dd HH:mm:ss"
            onChange={(value) => {
              let group = "minute";
              let diff = differenceInHours(value[1], value[0]);

              if (diff > 2 && diff < 49) {
                group = "hour";
              } else if (diff > 48) {
                group = "day";
              }

              setContext({
                ...context,
                f_context: {
                  ...context.f_context,
                  fromDttm: value[0],
                  toDttm: value[1],
                  group: group,
                },
              });
              setvalue({
                fromDttm: value[0],
                toDttm: value[1],
                group: group,
              });
            }}
          />
        </Grid>
        <Grid item pl={1}>
          <Button
            onClick={() =>
              setContext({
                ...context,
                f_context: {
                  ...context.f_context,
                  fromDttm: value["fromDttm"],
                  toDttm: value["toDttm"],
                  group: value["group"],
                },
                refetchQs: true,
              })
            }
            style={{
              backgroundColor: "#2092bb",
              marginBottom: ".2rem",
              borderRadius: ".25rem",
              color: "white",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              height: "1.75rem",
              textTransform: "none",
              marginTop: ".05rem",
            }}
          >
            <RefreshIcon
              Tooltip="Refetch"
              sx={{ color: "white",marginTop:'-.2rem', paddingTop: ".2rem" }}
            />
            {"Refresh"}
          </Button>
        </Grid>
        {/* <Grid item pl={1}>
          <Filter />
        </Grid>
        <Grid item pl={1}>
          <ClearFilter />
        </Grid> */}
      </Grid>
    </Box>
  );
}

export default TimeRangePicker;
