import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useQuery } from "@apollo/client";
import { tables } from "../query/query";
import { Context } from "../context/Context";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Switch from "@mui/material/Switch";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Typography from "@mui/material/Typography";

const ITEM_HEIGHT = 70;

function getStyles(name, indexName, theme) {
  return {
    fontWeight:
      indexName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function IndexSelect() {
  const theme = useTheme();
  const [context, setContext] = React.useContext(Context);
  const [indexName, setIndexName] = React.useState([context.f_context.table]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [search, setSearch] = React.useState("");
  const [isSelectDisabled, setIsSelectDisabled] = React.useState(false);
  const [showAlias, setShowAlias] = React.useState(true);

  React.useEffect(() => {
    setIndexName(context.f_context.table);
  }, [context.f_context.table]);
  // query tables
  const { data, loading, error } = useQuery(tables, {
    fetchPolicy: "cache-first",
    variables: {
      input: "",
    },
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (value) => {
    setIndexName(value);

    if (value === "alert" || value === "sa") {
      setContext({
        ...context,
        f_context: { ...context.f_context, table: value },
        sColumns: [
          { field: "alert.created", type: "datetime", __typename: "column" },
          { field: "source", type: "json", __typename: "column" },
        ],
      });
    } else {
      setContext({
        ...context,
        f_context: { ...context.f_context, table: value },
        sColumns: [
          { field: "event.created", type: "datetime", __typename: "column" },
          { field: "source", type: "json", __typename: "column" },
        ],
      });
    }

    handleClose();
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };

  const handleKeyDown = (event) => {
    event.stopPropagation();
  };

  const handleToggle = () => {
    setShowAlias((prev) => !prev);
  };

  const menuItems = data ? data.getTables : [];

  // const filteredItems = menuItems.filter((item) =>
  //   item.toLowerCase().includes(search.toLowerCase())
  // );

  const tooltips = {
    ads: "Deception tools, honeypots etc.,",
    alert: "Alerts from Blusapphire",
    audit_log: "Linux audit logs, auditd, auditbeat etc.,",
    auth: "Identity Management, Auth, Cisco Duo etc.,",
    cloud_aws: "Cloudtrail, Cloudwatch, AWS WAF etc.,",
    cloud_azure: "Entra ID, office 365, Azure firewall etc.,",
    cloud_gcp: "Google cloud logs",
    db: "Mysql, Postgres, etc.,",
    dhcp: "Dhcp records, windows dhcp, linux dhcp, infoblox, efficient IP etc.,",
    dns: "DNS records, windows dns, linux dns, infoblox, effecient IP etc.,",
    edr: "Endpoint Detection & Response, Crowdstrike, SentinnelOne, Defender, Symantec etc.,",
    erp: "Oracle ERP, SAP, Hana etc.,",
    fallback: "Records that don't confirm to any parser",
    flow: "Netflow, IPFIX etc.,",
    genie: "Blugenie tool logs",
    ips: "IDS/IPS, snort, Cisco UTD",
    lb: "Load Balacers, F5, Netscalar etc.,",
    linux: "Linux syslog, audit logs etc.,",
    nac: "Cisco NAC, Cisco ISE, Counteract, FortiNAC etc.,",
    nas: "Storage Device logs QNAP, Sinology etc.,",
    nbad: "BluSapphire Network Behavior Anomaly Detection",
    network: "Network Devices Switches, Routers etc.,",
    ngfw: "Next-Gen Firewalls, Palo Alto, Cisco UTM, Fortinet, Checkpoint etc.,",
    proxy_mail: "Email gateways Mimecast, Ironport etc.,",
    proxy_web: "Web Proxies Zscalar, ngnix, caddy etc.,",
    ra: "Remote Access Management tools - Citrix Netscalar, Guacamole, Microsoft remote desktop gateway etc.,",
    virtual: "Hypervisor logs Vmware, Microsoft etc.,",
    webserver: "Apache, Tomcat, websphere etc.,",
    windows: "Windows 2k+ security, system and application logs",
    wireless: "Wireless Controllers Aruba, cisco WLC etc.,",
  };

  const alias = {
    ads: "Active Defense",
    alert: "Alerts",
    audit_log: "Audit Logs",
    auth: "Identity Mgmt",
    cloud_aws: "Amazon Web Services",
    cloud_azure: "Microsoft Azure",
    cloud_gcp: "Google Cloud",
    db: "Database",
    dhcp: "DHCP logs",
    dns: "DNS logs",
    edr: "Endpoint Detection & Response",
    erp: "ERP Softwares",
    fallback: "FallBack Logs",
    flow: "NetFlow logs",
    genie: "BluGenie",
    ips: "IPS/IDS",
    lb: "Load Balancers",
    linux: "Linux Audit Logs",
    nac: "Network Access Control",
    nas: "Storage Devices",
    nbad: "Network Anomaly Logs",
    network: "Network Devices",
    ngfw: "Firewalls",
    proxy_mail: "Email Security",
    proxy_web: "Web Security",
    ra: "Remote Access",
    virtual: "Hypervisor / ESX",
    webserver: "Web Server",
    windows: "Windows",
    wireless: "Wireless Controllers",
  };

  const filteredItems = menuItems.filter((item) => {
    const aliasName = alias[item] || "";
    return (
      item.toLowerCase().includes(search.toLowerCase()) ||
      aliasName.toLowerCase().includes(search.toLowerCase())
    );
  });

  //disable when pivoting from notebooks

  let m = 0;
  React.useEffect(() => {
    if (m == 1) {
      setIsSelectDisabled(true);
    } else {
      setIsSelectDisabled(false);
    }
  }, [m == 1]);

  return (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{
          textTransform: "none",
          ml: "-245px !important",
          mt: "-2rem !important",
          minHeight: "1rem",
          fontSize: 22,
          fontWeight: 550,
          color: "#1a1c21",
          backgroundColor: "#fafbfd !important",
          "&:hover": { textDecoration: "underline" },
          display: "inline-flex",
          alignItems: "center",
          verticalAlign: "middle",
          padding: "0",
        }}
        disabled={isSelectDisabled}
      >
        <span
          style={{
            maxWidth: "220px", // Adjust this width as needed
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
            display: "inline-block",
            verticalAlign: "middle",
          }}
        >
          {showAlias ? alias[indexName] || indexName : indexName}
        </span>
        <KeyboardArrowDown sx={{ color: "grey" }} />
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "30ch",
          },
        }}
      >
        <Box
          sx={{
            padding: "8px 16px",
            position: "sticky",
            top: "0px !important",
            backgroundColor: "#fff",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            zIndex: 1,
          }}
        >
          <TextField
            value={search}
            onChange={handleSearchChange}
            onKeyDown={handleKeyDown}
            placeholder="Search"
            size="small"
            variant="outlined"
            autoFocus
            sx={{
              "& .MuiFilledInput-root": {
                paddingTop: "0.2rem",
                paddingBottom: "0.2rem",
                "& .MuiOutlinedInput-input": {
                  padding: "4px 8px",
                  height: "12px !important",
                  fontSize: "0.875rem",
                },
              },
              "& .MuiInputBase-input-MuiOutlinedInput-input": {
                height: "12px !important",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "rgba(0,0,0,0.23)",
              },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon sx={{ color: "lightgray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              ml: 1,
            }}
          >
            <Typography
              variant="caption"
              sx={{
                marginBottom: "-18px",
                marginLeft: "8px",
                backgroundColor: "#f0f0f0",
                borderRadius: "4px",
                width: "45px",
                paddingLeft: "2px",
                paddingRight: "2px",
                height: "18px",
              }}
            >
              {showAlias ? "Default" : "Tname"}
            </Typography>
            <Switch
              checked={showAlias}
              onChange={handleToggle}
              size="small"
              sx={{ ml: 1, marginTop: "22px" }}
            />
          </Box>
        </Box>
        {filteredItems.map((name) => (
          <Tooltip
            key={name}
            title={tooltips[name]}
            arrow
            placement="right-end"
            componentsProps={{
              tooltip: {
                sx: {
                  border: "1px solid lightgray",
                  bgcolor: "#f5f7fa",
                  color: "black",
                  "& .MuiTooltip-arrow": {
                    color: "common.lightgray",
                  },
                },
              },
            }}
          >
            <MenuItem
              key={name}
              selected={name === indexName}
              onClick={() => handleMenuItemClick(name)}
              // style={getStyles(name, indexName, theme)}
              sx={{
                ...getStyles(name, indexName, theme),
                "&:hover": {
                  fontWeight: theme.typography.fontWeightBold,
                  backgroundColor: theme.palette.action.hover,
                },
              }}
            >
              {showAlias ? alias[name] || name : name}
            </MenuItem>
          </Tooltip>
        ))}
      </Menu>
    </div>
  );
}
