import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import { Context } from "../context/Context";
import {
  Autocomplete,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { from, useQuery } from "@apollo/client";
import { logs } from "../query/query";
import { differenceInDays, endOfDay, startOfDay } from "date-fns";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8 + ITEM_PADDING_TOP,
    },
  },
};

function getValue(log, column) {
  try {
    let keys = column.field.trim().split(".");
    let tempObj = JSON.parse(log);

    keys.forEach((element) => {
      if (tempObj) {
        if (tempObj[element]) {
          tempObj = tempObj[element];
        } else {
          tempObj = " ";
        }
      }
    });

    if (column.type === "datetime" && tempObj !== null) {
      if (!tempObj.endsWith("Z")) {
        tempObj = tempObj.replace(" ", "T") + "Z";
      }

      return new Date(tempObj).toLocaleString();
    }

    return tempObj ? tempObj.toString() : "";
  } catch (e) {
    return [];
  }
}

function getType(type) {
  if (
    type.toLowerCase().indexOf("int") !== -1 ||
    type.toLowerCase().indexOf("float") !== -1
  ) {
    return "number";
  } else if (type.toLowerCase().indexOf("datetime") !== -1) {
    return "datetime-local";
  } else {
    return "text";
  }
}

function Value(props) {
  const {
    field,
    type,
    ferror,
    terror,
    condition,
    handleFromAuto,
    handleFrom,
    handleTo,
    value,
    toValue
  } = props;
  const [context, setContext] = React.useContext(Context);

  const { data, loading, error, fetchMore } = useQuery(logs, {
    fetchPolicy: "cache-first",

    variables: {
      input: {
        ...context["f_context"],
        fromDttm: context["f_context"]["fromDttm"],
        toDttm: context["f_context"]["toDttm"],
      },
    },
  });

  if (condition.toLowerCase().indexOf("range") !== -1) {
    return (
      <>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              size="small"
              error={ferror}
              labelId="field-select-label"
              id="value-text"
              label="Value From"
              type={getType(type)}
              onChange={handleFrom}
              value={value}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl fullWidth>
            <TextField
              size="small"
              error={terror}
              labelId="field-select-label"
              id="value-text"
              label="Value To"
              type={getType(type)}
              onChange={handleTo}
              value={toValue}
            />
          </FormControl>
        </Grid>
      </>
    );
  } else {
    return (
      <>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <Autocomplete
              id="freeSolo-value"
              autoSelect={true}
              onChange={handleFromAuto}
              value={value}
              freeSolo
              size="small"
              options={
                data
                  ? [
                      ...new Set(
                        data.getLogs.map((log) =>
                          getValue(log, { field: field, type: "" })
                        )
                      ),
                    ].sort()
                  : ["No Suggestions"]
              }
              renderInput={(params) => (
                <TextField size="small" {...params} label="value" />
              )}
            />
          </FormControl>
        </Grid>
      </>
    );
  }
}

export function Form(props) {
  const [context, setContext] = React.useContext(Context);
  const [field, setField] = React.useState("");
  const [type, setType] = React.useState("");
  const [from, setFrom] = React.useState(null);
  const [to, setTo] = React.useState(null);
  const [ferror, setFerror] = React.useState(false);
  const [terror, setTerror] = React.useState(false);
  const [selectedFilter, setSelectedFilter] = React.useState(props.selectedFilter);

  const [condition, setCondition] = React.useState("");

  React.useEffect(() => {
    if (selectedFilter) {
      setField(selectedFilter.field);
      setType(selectedFilter.dtype);
      setCondition(selectedFilter.op);
      // setFrom(selectedFilter.value);
      // console.log(selectedFilter.value, 'value');
      // console.log(selectedFilter, 'sv');
      if (selectedFilter.op.indexOf("RANGE") !== -1) {
        const values = selectedFilter.value.split("#@#");
        setFrom(values[0]);
        setTo(values[1]);
      } else {
        setFrom(selectedFilter.value);
        setTo(null);
      }
    } else {
      setField("");
      setType("");
      setCondition("");
      setFrom(null);
      setTo(null);
    }
  }, [selectedFilter]);

  const conditions = [
    "EXISTS",
    "NOT EXISTS",
    "IS",
    "IS NOT",
    "IN",
    "NOT IN",
    "CONTAINS",
    "NOT CONTAINS",
    "IS IN RANGE",
    "IS NOT IN RANGE",
    "IS IN SUBNET",
    "IS NOT IN SUBNET",
    "IS IN IP RANGE",
    "IS NOT IN IP RANGE",
  ];

  const handleField = (event, values) => {
    if (values) {
      let field = values;

      let column = context.aColumns.filter((c) => c.field === field);

      setField(values);
      setType(column[0].type);
      setCondition("");
      setFrom(null);
      setTo(null);
    } else {
      setField("");
    }
  };
  const handleCondition = (event) => {
    setCondition(event.target.value);
    // setFrom(null);
    // setTo(null);
  };

  const handleFrom = (event) => {
    setFrom(event.target.value);
  };

  const handleFromAuto = (event, value) => {
    // console.log(event, value, 'auto');
    setFrom(value);
  };

  const handleTo = (event) => {
    setTo(event.target.value);
  };

  const addFilter = () => {
    // console.log(selectedFilter, 'selected');
    // if(selectedFilter){
    //    let selectedid=selectedFilter.Id;
    //    let filtered = context.f_context.filter.filter((one) => one.Id !== selectedid);
    //   console.log(filtered, '1');
    //    setContext({
    //      ...context,
    //      f_context: { ...context.f_context, filter: filtered },
    //     });
    //     console.log(context.f_context.filter, '2');
    // }
    if (selectedFilter) {
    // If a filter is selected for editing, update its values instead of adding a new filter
      let updatedFilters = context.f_context.filter.map((filter) =>
        filter.Id === selectedFilter.Id
          ? {
              ...filter,
              field: field,
              dtype: type,
              op: condition,
              value:
                condition.indexOf("RANGE") !== -1
                  ? from + "#@#" + to
                  : condition === "EXISTS" || condition === "NOT EXISTS"
                  ? condition === "EXISTS"
                    ? "IS NOT NULL"
                    : "IS NULL"
                  : from,
            }
          : filter
      );
      setContext({
        ...context,
        f_context: {
          ...context.f_context,
          filter: updatedFilters,
        },
      });


      props.handleClose();
  }else{


    if (condition.indexOf("RANGE") === -1) {
      if (condition === "EXISTS" || condition === "NOT EXISTS") {
        // let V1 = "IS NULL";
        let V1 = condition === "EXISTS" ? "IS NOT NULL" : "IS NULL";
        // if (condition === "EXISTS") {
        //   V1 = "IS NOT NULL";
        // }

        let uniq = "id" + new Date().getTime();
        let filter = {
          Id: uniq,
          field: field,
          dtype: type,
          op: condition,
          // value: "",
          value: V1,
          status: "active",
        };

        setContext({
          ...context,
          f_context: {
            ...context.f_context,
            filter: [...context.f_context.filter, filter],
          },
        });


        // handleClose();

      } else if ( from) {
        let uniq = "id" + new Date().getTime();
        let filter = {
          Id: uniq,
          field: field,
          dtype: type,
          op: condition,
          value: from,
          status: "active",
        };

        setContext({
          ...context,
          f_context: {
            ...context.f_context,
            filter: [...context.f_context.filter, filter],
          },
        });

        props.handleClose();
      }
    } else {
      if (from && to) {
        let uniq = "id" + new Date().getTime();
        let filter = {
          Id: uniq,
          field: field,
          dtype: type,
          op: condition,
          value: from + "#@#" + to,
        };

        setContext({
          ...context,
          f_context: {
            ...context.f_context,
            filter: [...context.f_context.filter, filter],
          },
        });


      }
    }
  }
  props.handleClose();
  };

  const cancelFilter = () => {
    props.handleClose();
  };

  return (
    <Box sx={{ width: "30em" }} p={2}>
      <Grid container spacing={2}>
        <Grid item xs={7}>
          <FormControl fullWidth>
            <Autocomplete
              disablePortal
              value={field}
              onChange={handleField}
              options={[...context.aColumns].sort().map((c) => c.field)}
              renderInput={(params) => (
                <TextField

                  id="filter-field-select"
                  {...params}
                  label="Field"
                  size="small"
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={5}>
          <FormControl fullWidth>
            <InputLabel id="conditions-select-label" size="small">
              {""}
              Operator
            </InputLabel>
            <Select
              labelId="conditions-select-label"
              id="filter-field-select"
              value={condition}
              label="Condition"
              size="small"
              onChange={handleCondition}
            >
              {conditions.map((c) => {
               {/* if (type.toLowerCase() === "array(varchar)" && ["IS", "IS NOT"].includes(c.toUpperCase())) {
                return null;
              {/* } else if (type.toLowerCase() === "integer" && (c.toUpperCase() === "CONTAINS" || c.toUpperCase() === "NOT CONTAINS")) {
                return null; */}
              {/* } else */}
               if (c.indexOf("IN RANGE") === -1) {
                  return (
                    <MenuItem key={c} value={c}>
                      {c.toLocaleLowerCase()}
                    </MenuItem>
                  );
                } else if (
                  type.toLowerCase().indexOf("int") !== -1 ||
                  type.toLowerCase().indexOf("float") !== -1
                ) {
                  return (
                    <MenuItem key={c} value={c}>
                      {c.toLocaleLowerCase()}
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </FormControl>
        </Grid>
        {
          // condition !== 'IS NULL' && condition !== 'IS NOT NULL' &&
          condition !== "EXISTS" && condition !== "NOT EXISTS" && (
            <Value
              field={field}
              type={type}
              condition={condition}
              ferror={ferror}
              terror={terror}
              handleFromAuto={handleFromAuto}
              handleFrom={handleFrom}
              handleTo={handleTo}
              value={from}
              toValue={to}
            />
          )
        }
        {/* <Value
          field={field}
          type={type}
          condition={condition}
          ferror={ferror}
          terror={terror}
          handleFromAuto={handleFromAuto}
          handleFrom={handleFrom}
          handleTo={handleTo}
        /> */}
      </Grid>
      <Grid item xs={5}>
        <Box sx={{ textAlign: "right", paddingTop: "1em" }}>
          <Button
            size="small"
            variant="filled"
            sx={{
              borderRadius: "10px",
              backgroundColor: "white !important",
              height: "1.89rem",
              fontWeight: 550,
              color: "#11a1cd",
              "&:hover": { textDecoration: "underline" },
              textTransform: "capitalize",
            }}
            onClick={cancelFilter}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="filled"
            sx={{
              borderRadius: "5px",
              marginLeft: "10px",
              backgroundColor: "#2092bb !important",
              color: "white",
              height: "1.84rem",
              fontWeight: 550,
              textTransform: "capitalize",
            }}
            onClick={addFilter}
          >
            Save
          </Button>
        </Box>
      </Grid>
    </Box>
  );
}

export default function Filter() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedFilter, setSelectedFilter] = React.useState(null);

  const handleClick = (event,filter = null) => {
    setAnchorEl(event.currentTarget);
    setSelectedFilter(filter);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedFilter(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <>
      <IconButton
        onClick={handleClick}
        aria-describedby={id}
        color="primary"
        sx={{
          fontSize: 14,
          fontWeight: 400,
          marginLeft: "-1rem",
          paddingLeft: "1rem",
        }}
      >
        + Add Filter
      </IconButton>

      <Popover
        sx={{ marginTop: ".5rem", marginLeft: ".5rem" }}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Form handleClose={handleClose} selectedFilter={selectedFilter}/>
      </Popover>
    </>
  );
}


