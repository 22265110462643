import React from "react";
export const Context = React.createContext({
  sColumns: [
    { field: "event.created", type: "datetime", __typename: "column" },
    { field: "source", type: "json", __typename: "column" },
  ],
  f_context: {
    fromDttm: "",
    toDttm: "",
    rowLimit: 100,
    rowOffset: 0,
    filter: [],
    table: "ngfw",
    group: "minute",
    orderbyflag: false,
    requiredcolumns:[]
  },
  refetchQs: false,
  refactorDttm:""
})
